import React from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import { GridContainer, GridItem } from "../components/Grid";
import { Card, CardBody, CardIcon, CardHeader } from "../components/Card";
import MaterialTable from "../components/MaterialTable";
import Mail from "@material-ui/icons/Mail";
import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";

import * as fragments from "../constants/fragments";
import * as queries from "../constants/queries";
import * as mutations from "../constants/mutations";
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "../assets/jss";
import { useQuery, useMutation } from "react-apollo";
import { Typography } from "@material-ui/core";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

const InvitationsTable = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { addToast } = useToasts();

  const onError = (error) => {
    addToast(error.toString(), { appearance: "error" });
  };

  const onSuccess = (message) => {
    addToast(message.toString(), { appearance: "success" });
  };

  const { loading, data } = useQuery(queries.GET_INVITATIONS, {
    onError,
  });

  const has_invitations = !!data?.me.pending_invitations.length;

  const [acceptInvitation] = useMutation(mutations.ACCEPT_INVITATION, {
    refetchQueries: [
      { query: queries.GET_INVITATIONS },
      { query: queries.GET_GROUPS },
    ],
    update: (cache, { data: { accept_group_invitation } }) => {
      cache.writeFragment({
        id: `${accept_group_invitation.__typename}:${accept_group_invitation.id}`,
        fragment: fragments.GROUP_FIELDS,
        data: accept_group_invitation,
      });
    },
    onCompleted: (data) => {
      onSuccess(
        `${t("You have successfully joined the group")} ${
          data.accept_group_invitation.name
        }`,
      );
    },
    onError,
  });

  const [declineInvitation] = useMutation(mutations.DECLINE_INVITATION, {
    refetchQueries: ["GetInvitationsQuery"],
    onError,
  });

  const table_props = {
    data:
      data?.me?.pending_invitations.map((invitation) => ({
        groupName: invitation.group.name,
        groupId: invitation.group.id,
        date: new Intl.DateTimeFormat("sv-se").format(
          new Date(invitation.timestamp),
        ),
      })) || [],
    actions: [
      {
        icon: Check,
        tooltip: t("Accept Invitation"),
        onClick: (_, row) => {
          acceptInvitation({ variables: { groupId: row.groupId } }).catch(
            console.error,
          );
        },
      },
      {
        icon: Close,
        tooltip: t("Decline Invitation"),
        onClick: (_, row) => {
          declineInvitation({ variables: { groupId: row.groupId } }).catch(
            console.error,
          );
        },
      },
    ],
    columns: [
      { title: t("Group name"), field: "groupName", searchable: true },
      { title: t("Sent"), field: "date", searchable: true },
    ],
    options: {
      search: true,
      selection: false,
      actionsColumnIndex: -1,
      toolbar: true,
      paging: false,
      showTitle: false,
      draggable: true,
      detailPanelType: "single",
    },
    isLoading: loading,
    onRowClick: (event, row, toggleDetailPanel) =>
      row.allow_edit && toggleDetailPanel(),
    localization: {
      body: {
        emptyDataSourceMessage: t("You don't have any invitations"),
      },
    },
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              {" "}
              <Mail />{" "}
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{t("My invitations")}</h4>
          </CardHeader>

          <CardBody>
            {data &&
              (has_invitations ? (
                <>
                  <Typography color="textSecondary">
                    {t("InvitationsPageDescription")}
                  </Typography>
                  <br />
                  <MaterialTable {...table_props} />
                </>
              ) : (
                <Typography color="textSecondary">
                  {t("You don't have any unanswered invitations.")}
                </Typography>
              ))}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default InvitationsTable;
