import React from "react";
import { useTranslation } from "react-i18next";

import Input from "components/Input";
import Button from "components/Button";
import Dialog from "components/Dialog";
import { CSVLink } from "react-csv";
import { useToasts } from "react-toast-notifications";
import { useQuery } from "react-apollo";

import * as queries from "constants/queries";

const DownloadCollarReport = ({
  isOpen,
  close,
  done,
  collars,
  metrics,
  startDate,
  endDate,
}) => {
  const { addToast } = useToasts();
  const { i18n } = useTranslation();

  const onError = (error) => {
    addToast(error.toString(), { appearance: "error" });
  };

  const { data, loading } = useQuery(queries.CSV, {
    variables: {
      collarIds: collars.map((c) => c.id),
      startDate: startDate.valueOf(),
      endDate: endDate.valueOf(),
      metricFields: metrics.map(({ field }) => field),
      language: i18n.language === "sv" ? "SWEDISH" : "ENGLISH",
    },
    onError,
  });

  const { t } = useTranslation();

  let [filename, set_filename] = React.useState("");

  if (!filename) {
    filename = `export-${new Intl.DateTimeFormat("sv-se").format(
      new Date(),
    )}.csv`;
  }

  return (
    <Dialog
      open={isOpen}
      dialogProps={{ fullWidth: true, maxWidth: "sm" }}
      Actions={() => (
        <>
          <Button type="button" color="transparent" onClick={close}>
            {t("Cancel")}
          </Button>

          <CSVLink
            style={{ color: "white" }}
            filename={filename.endsWith(".csv") ? filename : filename + ".csv"}
            data={data ? data.csv : ""}
            onClick={() => {
              if (!loading) {
                done();
                set_filename("");
              }
            }}
          >
            <Button type="button" color="info" disabled={loading}>
              {loading ? t("Waiting for data...") : t("Export")}
            </Button>
          </CSVLink>
        </>
      )}
      content={
        <>
          <Input
            formControlProps={{ fullWidth: true }}
            labelText={t("Choose a filename (optional)")}
            inputProps={{
              onChange: (e) => {
                set_filename(e.target.value);
              },
            }}
          />
        </>
      }
    />
  );
};

export default DownloadCollarReport;
