import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as queries from "../../constants/queries";
import * as mutations from "../../constants/mutations";
import * as fragments from "../../constants/fragments";
import { useToasts } from "react-toast-notifications";
import SubTable from "../../components/SubTable";
import PeopleOutlineSharpIcon from "@material-ui/icons/PeopleOutlineSharp";
import { AddBox, DeleteOutline } from "@material-ui/icons";

/**
 * Table used for managing group members. This is only supposed to be shown to
 * admin users.
 */
export default function EditGroupUsersTable({ id, onGroupMembersEdited }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const onError = (error) =>
    addToast(error.toString(), { appearance: "error" });

  const { loading: group_users_loading, data: group_users_data } = useQuery(
    queries.GET_GROUP_USERS,
    {
      variables: { id },
      onError,
    },
  );

  const { loading: users_loading, data: users_data } = useQuery(
    queries.GET_USERS,
    {
      onError,
    },
  );

  const group_members = group_users_data?.group?.members || [];
  const group_owner_id = group_users_data?.group?.owner?.id;

  const is_in_group = (user_id) => {
    return !!group_members.find((member) => {
      return member.id === user_id;
    });
  };

  const all_users =
    (users_data &&
      users_data.users &&
      users_data.users.map((obj) => ({ ...obj }))) ||
    [];

  all_users.forEach((member) => {
    member.is_included = is_in_group(member.id);
    member.is_owner = member.id === group_owner_id;
  });

  const [add_users_to_group] = useMutation(mutations.ADD_USERS_TO_GROUP, {
    update: (
      cache,
      {
        data: {
          add_users_to_group: { id, users, __typename },
        },
      },
    ) => {
      const data = cache.readFragment({
        id: `${__typename}:${id}`,
        fragment: fragments.GROUP_USERS_FRAGMENT,
      });
      cache.writeFragment({
        id: `${__typename}:${id}`,
        fragment: fragments.GROUP_USERS_FRAGMENT,
        data: { ...data, users },
      });
    },
    onCompleted: (data) => {
      onGroupMembersEdited(data.add_users_to_group.id);
    },
    onError,
  });

  const [delete_users_from_group] = useMutation(
    mutations.DELETE_USERS_FROM_GROUP,
    {
      update: (
        cache,
        {
          data: {
            delete_users_from_group: { id, users, __typename },
          },
        },
      ) => {
        const data = cache.readFragment({
          id: `${__typename}:${id}`,
          fragment: fragments.GROUP_USERS_FRAGMENT,
        });
        cache.writeFragment({
          id: `${__typename}:${id}`,
          fragment: fragments.GROUP_USERS_FRAGMENT,
          data: { ...data, users },
        });
      },
      onCompleted: (data) => {
        onGroupMembersEdited(data.delete_users_from_group.id);
      },
      onError,
    },
  );

  const table_props = {
    title: t("Edit group members"),
    titleIcon: PeopleOutlineSharpIcon,
    data: all_users,
    columns: [
      {
        title: t("Included"),
        field: "is_included",
        type: "boolean",
        defaultSort: "desc",
      },
      {
        title: t("Name"),
        field: "name",
        searchable: true,
        render: (row) => {
          return (
            <span>
              {row.name}
              {row.id === group_owner_id && (
                <strong style={{ marginLeft: 5 }}>({t("Owner")})</strong>
              )}
            </span>
          );
        },
      },
    ],
    actions: [
      () => ({
        isFreeAction: false,
        disabled: false,
        tooltip: t("IncludeAllTooltip"),
        icon: () => <AddBox />,
        onClick: (evt, rows) =>
          add_users_to_group({
            variables: { id, ids: rows.map((row) => row.id) },
          }),
      }),
      (rows) => ({
        isFreeAction: false,
        disabled: rows.some((row) => row.is_owner),
        tooltip: t("ExcludeAllTooltip"),
        icon: () => <DeleteOutline />,
        onClick: (evt, rows) =>
          delete_users_from_group({
            variables: { id, ids: rows.map((row) => row.id) },
          }),
      }),
    ],
    options: {
      selection: true,
      showSelectAllCheckbox: true,
      sorting: true,
      search: true,
      actionsColumnIndex: -1,
      toolbar: true,
      showTitle: true,
      paging: false,
    },
    isLoading: group_users_loading || users_loading,
  };

  return <SubTable {...table_props}></SubTable>;
}
