import gql from "graphql-tag";
import * as fragments from "./fragments";

export const CREATE_ACCESS_TOKEN = gql`
  mutation CREATE_ACCESS_TOKEN_mutation {
    create_access_token {
      token
    }
  }
`;

export const SIGN_IN = gql`
  mutation SignInMutation($login: String!, $password: String!) {
    sign_in(login: $login, password: $password) {
      token
    }
  }
`;

export const SIGN_UP = gql`
  mutation SignUpMutation(
    $username: String!
    $name: String!
    $phone_number: String
    $email: String!
    $ssn: String!
    $password: String!
    $org_id: String
    $org_name: String
    $org_address_streetwise: String
    $org_address_zip: String
    $org_address_city: String
  ) {
    sign_up(
      username: $username
      phone_number: $phone_number
      name: $name
      ssn: $ssn
      email: $email
      password: $password
      org_id: $org_id
      org_name: $org_name
      org_address_streetwise: $org_address_streetwise
      org_address_zip: $org_address_zip
      org_address_city: $org_address_city
    )
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmailMutation($token: String!) {
    verify_email(token: $token)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPasswordMutation($password: String!) {
    reset_password(password: $password)
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPasswordMutation($login: String!) {
    forgot_password(login: $login)
  }
`;

// User CUD
export const CREATE_USER = gql`
  mutation CreateUserMutation(
    $username: String!
    $email: String!
    $name: String!
    $ssn: String
    $phone_number: String
    $role: String!
    $is_active_user: Boolean!
    $is_active_mqtt: Boolean!
    $super: Int!
  ) {
    create_user(
      username: $username
      phone_number: $phone_number
      email: $email
      name: $name
      ssn: $ssn
      role: $role
      is_active_mqtt: $is_active_mqtt
      is_active_user: $is_active_user
      super: $super
    ) {
      id
      ...user_fields
    }
  }
  ${fragments.USER_FIELDS}
`;

export const UPDATE_USER = gql`
  mutation UpdateUserMutation(
    $id: ID!
    $email: String
    $name: String
    $password: String
    $ssn: String
    $phone_number: String
    $role: String
    $address_streetwise: String
    $address_zip: String
    $address_city: String
    $org_name: String
    $org_id: String
    $org_address_streetwise: String
    $org_address_zip: String
    $org_address_city: String
    $is_active_user: Boolean
    $is_active_mqtt: Boolean
    $is_email_verified: Boolean
    $super: Int
  ) {
    update_user(
      id: $id
      phone_number: $phone_number
      email: $email
      password: $password
      ssn: $ssn
      name: $name
      role: $role
      address_streetwise: $address_streetwise
      address_zip: $address_zip
      address_city: $address_city
      org_name: $org_name
      org_id: $org_id
      org_address_streetwise: $org_address_streetwise
      org_address_zip: $org_address_zip
      org_address_city: $org_address_city
      is_active_mqtt: $is_active_mqtt
      is_active_user: $is_active_user
      is_email_verified: $is_email_verified
      super: $super
    ) {
      id
      ...user_fields
    }
  }
  ${fragments.USER_FIELDS}
`;

export const DELETE_USER = gql`
  mutation DeleteUserMutation($id: ID!) {
    delete_user(id: $id) {
      id
    }
  }
`;

// Group CUD
export const CREATE_GROUP = gql`
  mutation CreateGroupMutation(
    $name: String!
    $description: String
    $ownerId: ID!
  ) {
    create_group(name: $name, description: $description, ownerId: $ownerId) {
      id
      ...group_fields
    }
  }
  ${fragments.GROUP_FIELDS}
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroupMutation(
    $id: ID!
    $name: String!
    $description: String
    $ownerId: ID!
  ) {
    update_group(
      id: $id
      name: $name
      description: $description
      ownerId: $ownerId
    ) {
      id
      ...group_fields
    }
  }
  ${fragments.GROUP_FIELDS}
`;

export const DELETE_GROUP = gql`
  mutation DeleteGroupMutation($id: ID!) {
    delete_group(id: $id) {
      id
    }
  }
`;

export const INVITE_TO_GROUP = gql`
  mutation InviteToGroupMutation($id: ID!, $email: String!) {
    invite_to_group(groupId: $id, email: $email) {
      email
    }
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($groupId: ID!) {
    accept_group_invitation(groupId: $groupId) {
      id
      ...group_fields
    }
  }
  ${fragments.GROUP_FIELDS}
`;

export const DECLINE_INVITATION = gql`
  mutation DeclineInvitation($groupId: ID!) {
    decline_group_invitation(groupId: $groupId) {
      id
      ...group_fields
    }
  }
  ${fragments.GROUP_FIELDS}
`;

export const ADD_USERS_TO_GROUP = gql`
  mutation AddUsersToGroupMutation($id: ID!, $ids: [ID!]!) {
    add_users_to_group(id: $id, ids: $ids) {
      id
      ...group_users
    }
  }
  ${fragments.GROUP_USERS_FRAGMENT}
`;

export const DELETE_USERS_FROM_GROUP = gql`
  mutation DeleteUsersFromGroupMutation($id: ID!, $ids: [ID!]!) {
    delete_users_from_group(id: $id, ids: $ids) {
      id
      ...group_users
    }
  }
  ${fragments.GROUP_USERS_FRAGMENT}
`;

export const ADD_COLLARS_TO_GROUP = gql`
  mutation AddCollarsToGroupMutation($id: ID!, $ids: [ID!]!) {
    add_collars_to_group(id: $id, ids: $ids) {
      id
      ...group_collars
    }
  }
  ${fragments.GROUP_COLLARS_FRAGMENT}
`;

export const DELETE_COLLARS_FROM_GROUP = gql`
  mutation DeleteCollarsFromGroupMutation($id: ID!, $ids: [ID!]!) {
    delete_collars_from_group(id: $id, ids: $ids) {
      id
      ...group_collars
    }
  }
  ${fragments.GROUP_COLLARS_FRAGMENT}
`;

// Collar CUD
export const CREATE_COLLAR = gql`
  mutation CreateCollarMutation(
    $name: String!
    $userId: ID!
    $vendorId: ID
    $key: String
    $note: String
    $description: String
  ) {
    create_collar(
      name: $name
      userId: $userId
      vendorId: $vendorId
      key: $key
      note: $note
      description: $description
    ) {
      id
      ...collar_fields
    }
  }
  ${fragments.COLLAR_FIELDS}
`;

export const UPDATE_COLLAR = gql`
  mutation UpdateCollarMutation(
    $id: ID!
    $name: String!
    $userId: ID!
    $vendorId: ID
    $key: String
    $note: String
    $description: String
  ) {
    update_collar(
      id: $id
      name: $name
      userId: $userId
      vendorId: $vendorId
      key: $key
      note: $note
      description: $description
    ) {
      id
      ...collar_fields
    }
  }
  ${fragments.COLLAR_FIELDS}
`;

export const DESCRIBE_COLLAR = gql`
  mutation DescribeCollarMutation(
    $id: ID!
    $note: String
    $description: String
  ) {
    describe_collar(id: $id, description: $description, note: $note) {
      id
      ...collar_fields
    }
  }
  ${fragments.COLLAR_FIELDS}
`;

export const DELETE_COLLAR = gql`
  mutation DeleteCollarMutation($id: ID!) {
    delete_collar(id: $id) {
      id
    }
  }
`;

// ExportTemplate CD

export const CREATE_EXPORT_TEMPLATE = gql`
  mutation CreateExportTemplateMutation(
    $name: String!
    $description: String
    $metricFields: [String]!
  ) {
    create_export_template(
      name: $name
      description: $description
      metricFields: $metricFields
    ) {
      id
      ...export_template_fields
    }
  }
  ${fragments.EXPORT_TEMPLATE_FIELDS}
`;

export const DELETE_EXPORT_TEMPLATE = gql`
  mutation DeleteExportTemplateMutation($id: ID!) {
    delete_export_template(id: $id)
  }
`;

// Vendor CUD
export const CREATE_VENDOR = gql`
  mutation CreateVendorMutation($name: String!, $description: String) {
    create_vendor(name: $name, description: $description) {
      id
      ...vendor_fields
    }
  }
  ${fragments.VENDOR_FIELDS}
`;

export const UPDATE_VENDOR = gql`
  mutation UpdateVendorMutation(
    $id: ID!
    $name: String!
    $description: String
  ) {
    update_vendor(id: $id, name: $name, description: $description) {
      id
      ...vendor_fields
    }
  }
  ${fragments.VENDOR_FIELDS}
`;

export const DELETE_VENDOR = gql`
  mutation DeleteVendorMutation($id: ID!) {
    delete_vendor(id: $id) {
      id
    }
  }
`;
