import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { NavbarLinks } from "../Navbar";
import { sidebarStyle } from "../../assets/jss/components";
import { Badge } from "@material-ui/core";
import euLogoImage from "../../assets/img/eu-logo.jpg";

const useStyles = makeStyles(sidebarStyle);

export default function Sidebar(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { color, logo, image, logoText, routes } = props;

  const links = (
    <List className={classes.list}>
      {routes
        .filter((route) => route.admin_links)
        .map((route, key) => {
          const is_the_active_route =
            window.location.pathname === route.layout + route.path;

          const listItemClasses = classNames({
            [classes[color]]: is_the_active_route,
          });
          const whiteFontClasses = classNames({
            [classes.whiteFont]: is_the_active_route,
          });

          return (
            <NavLink
              to={route.layout + route.path}
              className={classes.item}
              activeClassName="active"
              key={key}
              title={t(route.help_text)}
            >
              <ListItem
                button
                className={classNames(classes.itemLink, listItemClasses)}
              >
                {route.notifications ? (
                  <Badge
                    badgeContent={route.notifications}
                    color="error"
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  >
                    <route.icon />
                  </Badge>
                ) : (
                  <route.icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  />
                )}
                <ListItemText
                  primary={t(route.name_key)}
                  className={classNames(classes.itemText, whiteFontClasses)}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          );
        })}
    </List>
  );

  const euLogo = (
    <img src={euLogoImage} alt={"EU logo"} className={classes.euLogo} />
  );

  const brand = (
    <div className={classes.logo}>
      <a href="/" className={classNames(classes.logoLink)}>
        <div className={classes.logoImage}>
          {" "}
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{ paper: classNames(classes.drawerPaper) }}
          onClose={props.handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
        >
          {brand}

          <div className={classes.sidebarWrapper}>
            <NavbarLinks />
            {links}
            {euLogo}
          </div>

          {image && (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          )}
        </Drawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          anchor="left"
          open
          classes={{ paper: classNames(classes.drawerPaper) }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {links}
            {euLogo}
          </div>

          {image && (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          )}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
