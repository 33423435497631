import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import classNames from "classnames";

import Lock from "@material-ui/icons/Lock";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { GridContainer, GridItem } from "../components/Grid";
import CustomInput from "../components/Input";
import Button from "../components/Button";
import { Card, CardFooter, CardBody, CardHeader } from "../components/Card";

import { signinStyle } from "../assets/jss/views";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import * as mutations from "../constants/mutations";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import token from "../constants/token";

const useStyles = makeStyles(signinStyle);

export default function Signin() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [login, set_login] = React.useState("");
  const [password, set_password] = React.useState("");

  const onCompleted = (data) => {
    set_login("");
    set_password("");

    token.update_refresh_token(data.sign_in.token);

    history.push("/admin/user");
  };

  const { addToast } = useToasts();
  const onError = (error) => {
    let message = error.toString();
    if (message.match(/no.*user/i)) {
      message = t("There exists no user with that e-mail or password");
    }
    if (message.match(/invalid.*password/i)) {
      message = t("Invalid password");
    }
    if (message.match(/new verification e-mail/i)) {
      message = t(
        "User email is not verified, sending new verification e-mail",
      );
    }
    addToast(message, { appearance: "error" });
  };

  const [sign_in, { loading }] = useMutation(mutations.SIGN_IN, {
    variables: { login, password },
    onCompleted,
    onError,
  });

  const history = useHistory();

  const is_disabled = !(password && login) || loading;

  const onSubmit = (event) => {
    sign_in().catch(console.error);
    event.preventDefault();
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={5} lg={4}>
          <form className={classes.form} onSubmit={onSubmit}>
            <Card>
              <CardHeader
                className={classNames(classes.cardHeader, classes.textCenter)}
                color="success"
              >
                <h4 className={classes.cardTitle}>{t("SignInHeader")}</h4>
              </CardHeader>

              <CardBody>
                <CustomInput
                  id="login"
                  formControlProps={{ fullWidth: true }}
                  labelText={t("Username or Email")}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon
                          className={classes.inputAdornmentIcon}
                        />
                      </InputAdornment>
                    ),
                    value: login,
                    onChange: (event) => set_login(event.target.value),
                  }}
                />
                <CustomInput
                  id="password"
                  formControlProps={{ fullWidth: true }}
                  labelText={t("Password")}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    value: password,
                    onChange: (event) => set_password(event.target.value),
                  }}
                />
              </CardBody>

              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  type="submit"
                  disabled={is_disabled}
                  color="success"
                  simple
                  size="lg"
                  block
                >
                  {" "}
                  {t("Log in")}{" "}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
