import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";

import { GridContainer, GridItem } from "../components/Grid";
import { Card, CardBody, CardIcon, CardHeader } from "../components/Card";

import { cardTitle } from "../assets/jss";
import { useMutation, useQuery } from "@apollo/react-hooks";

import * as queries from "../constants/queries";
import * as mutations from "../constants/mutations";
import * as fragments from "../constants/fragments";

import MaterialTable from "../components/MaterialTable";
import CollarGroups from "./detailPanels/collarGroups";

import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { buttonStyle } from "assets/jss/components";
import { Typography } from "@material-ui/core";

const styles = {
  ...buttonStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const isHex = (s) => {
  const regExp = /^[-+]?[0-9A-Fa-f]+\.?[0-9A-Fa-f]*?$/;
  return typeof s === "string" && regExp.test(s);
};

const useStyles = makeStyles(styles);

export default function CollarsTable() {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const onError = (error) => {
    const message = error.message || String(error);
    addToast(message, { appearance: "error" });
  };

  const { data: get_me_result } = useQuery(queries.GET_ME, { onError });
  const me = get_me_result?.me;
  const is_admin = me?.role === "ADMIN";

  const { loading, data, refetch } = useQuery(
    is_admin ? queries.GET_COLLARS_AND_USERS : queries.GET_COLLARS,
    { onError },
  );

  const table_data =
    (data &&
      data.collars &&
      data.collars
        .filter((collar) => (is_admin ? true : collar?.user.id === me.id))
        .map((collar) => ({
          ...collar,
          user_id: collar?.user?.id,
          vendor_id: collar?.vendor?.id,
          allow_edit: true,
          allow_detail_panel: me?.id === collar.user_id || is_admin,
        }))) ||
    [];

  const [create_collar] = useMutation(mutations.CREATE_COLLAR, {
    update: (cache, { data: { create_collar } }) => {
      cache.writeFragment({
        id: `${create_collar.__typename}:${create_collar.id}`,
        fragment: fragments.COLLAR_FIELDS,
        data: create_collar,
      });
    },
    onCompleted: refetch,
    onError,
  });

  const [update_collar] = useMutation(mutations.UPDATE_COLLAR, {
    update: (cache, { data: { update_collar } }) => {
      cache.writeFragment({
        id: `${update_collar.__typename}:${update_collar.id}`,
        fragment: fragments.COLLAR_FIELDS,
        data: update_collar,
      });
    },
    onCompleted: refetch,
    onError,
  });

  const [describe_collar] = useMutation(mutations.DESCRIBE_COLLAR, {
    update: (cache, { data: { describe_collar } }) => {
      cache.writeFragment({
        id: `${describe_collar.__typename}:${describe_collar.id}`,
        fragment: fragments.COLLAR_FIELDS,
        data: describe_collar,
      });
    },
    onCompleted: refetch,
    onError,
  });

  const [delete_collar] = useMutation(mutations.DELETE_COLLAR, {
    update: (cache, { data: { delete_collar } }) => {
      cache.writeFragment({
        id: `${delete_collar.__typename}:${delete_collar.id}`,
        fragment: fragments.COLLAR_FIELDS,
        data: null,
      });
    },
    onCompleted: refetch,
    onError,
  });

  const classes = useStyles();

  const table_props = {
    data: table_data,
    columns: [
      {
        title: t("Collar Name"),
        field: "name",
        searchable: true,
        editable: is_admin ? "always" : "never",
        validate: (row) =>
          !isHex(row.name)
            ? { isValid: false, helperText: t("Provide a collar number") }
            : true,
      },
      {
        title: t("Owner"),
        field: "user_id",
        ...(is_admin ? {} : { render: (row) => row?.user?.name || "" }),
        searchable: true,
        hidden: !is_admin,
        lookup: is_admin
          ? data?.users?.reduce((acc, user) => {
              acc[user.id] = user.name;
              return acc;
            }, {})
          : null,
        initialEditValue: data?.me?.id,
        editable: is_admin ? "always" : "never",
      },
      {
        title: t("Key"),
        field: "key",
        searchable: true,
        initialEditValue: "",
        editable: is_admin ? "always" : "never",
      },
      {
        title: t("Vendor"),
        field: "vendor_id",
        ...(is_admin ? {} : { render: (row) => row?.vendor?.name || "" }),
        searchable: true,
        lookup: is_admin
          ? data?.vendors?.reduce((acc, vendor) => {
              acc[vendor.id] = vendor.name;
              return acc;
            }, {})
          : null,
        initialEditValue: Math.min(...(data?.vendors?.map((v) => v.id) || [])),
        editable: is_admin ? "always" : "never",
      },
      {
        title: t("Description"),
        field: "description",
        searchable: true,
        editable: "always",
      },
      {
        title: t("Note"),
        field: "note",
        searchable: true,
        editable: "always",
      },
    ],
    editable: {
      isEditable: (row) => row.allow_edit,
      isDeletable: (row) => row.allow_edit,
      externalAddButton: { text: t("Add collar") },
      onRowAdd: is_admin
        ? (row) => {
            if (!row.user_id) {
              onError(t("Please select a user!"));
              return Promise.reject();
            }
            return create_collar({
              variables: {
                ...row,
                userId: row.user_id,
                vendorId: row.vendor_id,
              },
            });
          }
        : null,
      onRowUpdate: (row) => {
        if (!row.user) {
          onError(t("Please select a user!"));
          return Promise.reject();
        }
        if (is_admin) {
          // TODO: Allow note and desciption to be set here as well.
          return update_collar({
            variables: {
              ...row,
              userId: row.user_id,
              vendorId: row.vendor_id,
            },
          });
        }

        return describe_collar({
          variables: {
            id: row.id,
            description: row.description,
            note: row.note,
          },
        });
      },
      onRowDelete: is_admin
        ? (row) => {
            return delete_collar({ variables: { id: row.id } });
          }
        : null,
    },
    detailPanel: [
      (row) => ({
        isFreeAction: true,
        disabled: !row.allow_detail_panel,
        icon: () => <ArrowRight />,
        openIcon: () => <ArrowDropDown />,
        tooltip: t("Show the collar's groups"),
        render: (row) => <CollarGroups id={row.id} />,
      }),
    ],
    options: {
      search: true,
      actionsColumnIndex: -1,
      toolbar: true,
      showTitle: false,
      paging: false,
      detailPanelType: "single",
    },
    isLoading: loading,
    onRowClick: (event, row, toggleDetailPanel) =>
      row.allow_edit && toggleDetailPanel(),
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color={"success"} icon>
            <CardIcon color={"success"}>
              {" "}
              <GpsFixedIcon />{" "}
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{t("My collars")}</h4>
          </CardHeader>

          <CardBody>
            <Typography color="textSecondary">
              {t("CollarsPageDescription")}
            </Typography>
            <br />
            <MaterialTable {...table_props} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
