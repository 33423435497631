import gql from "graphql-tag";
import * as fragments from "./fragments";

export const IS_SIGNED_IN = gql`
  query IsSignedInQuery {
    is_signed_in
  }
`;

export const GET_ME = gql`
  query GetMeQuery {
    me {
      id
      ...user_fields
    }
  }
  ${fragments.USER_FIELDS}
`;

export const GET_INVITATIONS = gql`
  query GetInvitationsQuery {
    me {
      id
      role
      pending_invitations {
        email
        timestamp
        group {
          id
          name
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query GetUsersQuery($role: String) {
    users(role: $role) {
      id
      ...user_fields
    }
    me {
      id
      role
    }
  }
  ${fragments.USER_FIELDS}
`;

export const GET_GROUPS = gql`
  query GetGroupsQuery {
    me {
      name
      id
    }
    groups {
      id
      description
      name
      owner {
        id
        name
      }
      members {
        id
        name
      }
    }
  }
`;

export const GET_GROUP_USERS = gql`
  query GetGroupUsersQuery($id: ID!) {
    me {
      id
      role
    }
    group(id: $id) {
      id
      members {
        id
        name
      }
      owner {
        id
      }
      members {
        id
        name
      }
    }
  }
`;

export const GET_GROUP_COLLARS = gql`
  query GetGroupCollarsQuery($id: ID!) {
    group(id: $id) {
      id
      owner {
        id
      }
      collars {
        id
        name
        user {
          id
          name
        }
      }
    }
    collars {
      id
      name
      user {
        id
        name
      }
    }
  }
`;

export const GET_USER_COLLARS = gql`
  query GetUserCollarsQuery($id: ID!) {
    user(id: $id) {
      id
      my_collars {
        id
        name
        user {
          id
          name
        }
        groups {
          id
          name
        }
      }
    }
  }
`;

export const GET_USER_GROUPS = gql`
  query GetUserGroupsQuery($id: ID!) {
    user(id: $id) {
      id
      my_groups {
        id
        name
        owner {
          id
          name
        }
      }
      groups {
        id
        name
        owner {
          id
          name
        }
      }
    }
  }
`;

export const GET_COLLAR_GROUPS = gql`
  query GetCollarGroupsQuery($id: ID!) {
    collar(id: $id) {
      id
      groups {
        id
        name
        owner {
          id
          name
        }
      }
    }
  }
`;

export const GET_VENDOR_COLLARS = gql`
  query GetVendorCollarsQuery($id: ID!) {
    vendor(id: $id) {
      id
      name
      collars {
        id
        name
        user {
          id
          name
        }
        vendor {
          id
          name
        }
      }
    }
  }
`;

export const GET_COLLARS = gql`
  query GetCollarsQuery {
    collars {
      id
      name
      description
      note
      key
      user {
        id
        name
      }
      vendor {
        id
        name
      }
    }
    me {
      id
      role
    }
  }
`;

export const GET_COLLARS_AND_USERS = gql`
  query GetCollarsAndUsersQuery {
    collars {
      id
      name
      description
      note
      key
      user {
        id
        name
      }
      vendor {
        id
        name
      }
    }
    vendors {
      id
      name
    }
    users {
      id
      name
    }
    me {
      id
      role
    }
  }
`;

export const CSV = gql`
  query CSVQuery(
    $collarIds: [ID]!
    $startDate: Float!
    $endDate: Float!
    $metricFields: [String]!
    $language: SupportedLanguage
  ) {
    csv(
      collarIds: $collarIds
      startDate: $startDate
      endDate: $endDate
      metricFields: $metricFields
      language: $language
    )
  }
`;

export const EXPORTABLE_METRICS = gql`
  query ExportableMetricsQuery($language: SupportedLanguage) {
    exportable_metrics(language: $language) {
      field
      ...export_metric_fields
    }
    export_templates(language: $language) {
      id
      ...export_template_fields
    }
  }
  ${fragments.EXPORT_TEMPLATE_FIELDS}
  ${fragments.EXPORT_METRIC_FIELDS}
`;

export const GET_VENDORS = gql`
  query GetVendorsQuery {
    vendors {
      id
      ...vendor_fields
    }
  }
  ${fragments.VENDOR_FIELDS}
`;
