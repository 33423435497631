import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GridItem, GridContainer } from "../components/Grid";
import Button from "../components/Button";
import CustomInput from "../components/Input";
import { userProfileStyle } from "../assets/jss/views";

import { Card, CardFooter, CardBody, CardHeader } from "../components/Card";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as queries from "../constants/queries";
import InputAdornment from "@material-ui/core/InputAdornment";

import Face from "@material-ui/icons/Face";
import Lock from "@material-ui/icons/Lock";
import Email from "@material-ui/icons/AlternateEmail";
import Phone from "@material-ui/icons/Phone";
import Role from "@material-ui/icons/SupervisorAccount";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";

import * as mutations from "../constants/mutations";
import { useToasts } from "react-toast-notifications";
import * as fragments from "../constants/fragments";
import is_valid_email from "constants/emailValidation";

const useStyles = makeStyles(userProfileStyle);

export default function UserProfile() {
  const { data } = useQuery(queries.GET_ME);

  return data && data.me ? <Profile data={data} /> : <div></div>;
}

function Profile({ data: { me } }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const onError = (error) =>
    addToast(error.toString(), { appearance: "error" });
  const onSuccess = () =>
    addToast(t("Your profile has been updated successfully"), {
      appearance: "success",
    });

  const passwordMinLength = 8;
  const nameMinLength = 2;

  const [username, set_username] = React.useState(me.username);
  const [ssn, set_ssn] = React.useState(me.ssn);
  const [ssn_confirmation, set_ssn_confirmation] = React.useState(me.ssn);
  const [role, set_role] = React.useState(me.role);

  const [address_streetwise, set_address_streetwise] = React.useState(
    me.address_streetwise || "",
  );
  const [address_city, set_address_city] = React.useState(
    me.address_city || "",
  );
  const [address_zip, set_address_zip] = React.useState(me.address_zip || "");

  const [org_name, set_org_name] = React.useState(me.org_name || "");
  const [org_id, set_org_id] = React.useState(me.org_id || "");
  const [org_address_streetwise, set_org_address_streetwise] = React.useState(
    me.org_address_streetwise || "",
  );
  const [org_address_city, set_org_address_city] = React.useState(
    me.org_address_city || "",
  );
  const [org_address_zip, set_org_address_zip] = React.useState(
    me.org_address_zip || "",
  );

  const [name, set_name] = React.useState(me.name || "");
  const [email, set_email] = React.useState(me.email || "");
  const [phone_number, set_phone_number] = React.useState(
    me.phone_number || "",
  );

  const [password, set_password] = React.useState("");
  const [password_confirmation, set_password_confirmation] = React.useState("");

  const [delete_dialog_open, set_delete_dialog_open] = React.useState(false);

  const openDeleteDialog = () => set_delete_dialog_open(true);
  const closeDeleteDialog = () => set_delete_dialog_open(false);

  const onUserDeleted = () => {
    window.location.href = "/signout";
  };

  const [update_user] = useMutation(mutations.UPDATE_USER, {
    variables: {
      id: me.id,
      email,
      phone_number,
      role,
      password,
      name,
      ssn,
      address_zip,
      address_city,
      address_streetwise,
      org_name,
      org_id,
      org_address_zip,
      org_address_city,
      org_address_streetwise,
    },
    update: (cache, { data: { update_user } }) => {
      cache.writeFragment({
        id: `${update_user.__typename}:${update_user.id}`,
        fragment: fragments.USER_FIELDS,
        data: update_user,
      });
    },
    onError,
    onCompleted: onSuccess,
  });

  const [delete_my_account] = useMutation(mutations.DELETE_USER, {
    variables: { id: me.id },
    onError,
    onCompleted: onUserDeleted,
  });

  const is_disabled =
    name.length < nameMinLength ||
    !ssn ||
    ssn !== ssn_confirmation ||
    (!me.ssn && !ssn.match(/[0-9]{8}-[0-9]{4}/)) ||
    !is_valid_email(email) ||
    (password !== "" && password.length < passwordMinLength) ||
    password !== password_confirmation;

  const onSubmit = (e) => {
    e.preventDefault();
    if (!is_disabled) {
      update_user().catch(onError);
    }
  };

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form onSubmit={onSubmit}>
              <CardHeader color="success">
                <h4 className={classes.cardTitleWhite}>{t("Edit profile")}</h4>
                <p className={classes.cardCategoryWhite}>
                  {t("Complete your profile")}
                </p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("Username")}
                      required={true}
                      id="username"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        value: username,
                        disabled: true,
                        onChange: (event) => set_username(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      required={true}
                      labelText={t("Role")}
                      id="role"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Role className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        type: "string",
                        autoComplete: "off",
                        value: role,
                        disabled: true,
                        onChange: (event) => set_role(event.target.value),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("Full name")}
                      required={true}
                      id="name"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        error: name.length < nameMinLength,
                        inputProps: {
                          minLength: nameMinLength,
                        },
                        value: name,
                        onChange: (event) => set_name(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem
                    xs={me.ssn ? 12 : 6}
                    sm={me.ssn ? 12 : 6}
                    md={me.ssn ? 6 : 3}
                  >
                    <CustomInput
                      labelText={t("Social Security Number")}
                      required={true}
                      id="ssn"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        value: ssn,
                        inputProps: { pattern: "[0-9]{8}-[0-9]{4}" },
                        disabled: Boolean(me.ssn),
                        onChange: (event) => set_ssn(event.target.value),
                      }}
                    />
                  </GridItem>
                  {!me.ssn && (
                    <GridItem xs={6} sm={6} md={3}>
                      <CustomInput
                        labelText={t("Confirm Social Security Number")}
                        required={true}
                        id="ssn"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          error: ssn !== ssn_confirmation,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleIcon
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                          value: ssn_confirmation,
                          inputProps: { pattern: "[0-9]{8}-[0-9]{4}" },
                          onChange: (event) =>
                            set_ssn_confirmation(event.target.value),
                        }}
                      />
                    </GridItem>
                  )}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("Email address")}
                      required={true}
                      id="email-address"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        value: email,
                        error: !is_valid_email(email),
                        type: "email",
                        onChange: (event) => set_email(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("Phone number")}
                      required={true}
                      id="phone-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Phone className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        value: phone_number,
                        type: "tel",
                        onChange: (event) =>
                          set_phone_number(event.target.value),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("Address")}
                      id="address_streetwise"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        value: address_streetwise,
                        onChange: (event) =>
                          set_address_streetwise(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <CustomInput
                      labelText={t("ZIP Code")}
                      id="address_zip"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        value: address_zip,
                        onChange: (event) =>
                          set_address_zip(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={3} md={3}>
                    <CustomInput
                      labelText={t("City")}
                      id="address_city"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        value: address_city,
                        onChange: (event) =>
                          set_address_city(event.target.value),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("Organization name")}
                      id="org_name"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        value: org_name,
                        onChange: (event) => set_org_name(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("Company registration number")}
                      id="org_id"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        value: org_id,
                        onChange: (event) => set_org_id(event.target.value),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("Organization address")}
                      id="org_address_streetwise"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        value: org_address_streetwise,
                        onChange: (event) =>
                          set_org_address_streetwise(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={3}>
                    <CustomInput
                      labelText={t("ZIP Code")}
                      id="org_address_zip"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        value: org_address_zip,
                        onChange: (event) =>
                          set_org_address_zip(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={3} md={3}>
                    <CustomInput
                      labelText={t("City")}
                      id="org_address_city"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        value: org_address_city,
                        onChange: (event) =>
                          set_org_address_city(event.target.value),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("New password")}
                      id="password"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        type: "password",
                        autoComplete: "new-password",
                        error:
                          password !== "" &&
                          password.length < passwordMinLength,
                        value: password,
                        inputProps: {
                          minLength: passwordMinLength,
                        },
                        onChange: (event) => set_password(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText={t("Password confirmation")}
                      id="password_confirmation"
                      formControlProps={{ fullWidth: true }}
                      errorMessage={t("Must match the field above")}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        type: "password",
                        error: password !== password_confirmation,
                        inputProps: {
                          pattern: password,
                        },
                        autoComplete: "new-password",
                        value: password_confirmation,
                        onChange: (event) =>
                          set_password_confirmation(event.target.value),
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  onClick={onSubmit}
                  disabled={is_disabled}
                  color="success"
                >
                  {t("Update Profile")}
                </Button>
                <Button onClick={openDeleteDialog} color="danger">
                  {t("Delete account")}
                </Button>
                <Dialog open={delete_dialog_open} onClose={closeDeleteDialog}>
                  <DialogTitle>{t("Warning!")}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t(
                        "Are you sure you want to delete your user account? You can't undo this action.",
                      )}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={delete_my_account} color="danger">
                      {t("Delete account")}
                    </Button>
                    <Button onClick={closeDeleteDialog}>{t("Cancel")}</Button>
                  </DialogActions>
                </Dialog>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
