import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";

import history from "./constants/history";
import token from "./constants/token";
import { SERVER_URL } from "constants/environment";

export const sign_out = (client) => {
  token.remove_all();

  client.clearStore().then(() => history.push("/auth/signin"));
  return null;
};

const fetch_access_token = async () => {
  const _token = token.read_refresh_token();

  if (!_token) {
    return false;
  }

  const refresh_token = fetch(SERVER_URL, {
    method: "POST",
    headers: { authorization: _token, "Content-Type": "application/json" },
    body: JSON.stringify({
      query: "mutation { create_access_token { token } }",
    }),
  });

  return refresh_token
    .then((response) => response.json())
    .then((response) => {
      const new_token =
        response &&
        response.data &&
        response.data.create_access_token &&
        response.data.create_access_token.token;

      if (!new_token) {
        return false;
      }

      token.update_access_token(new_token);
      return new_token;
    })
    .catch((error) => {
      console.error(error);
      token.remove_all();
    });
};

const auth_link = setContext(async (_, { headers, ...context }) => {
  const access_token =
    token.read_access_token() || (await fetch_access_token());

  return {
    ...context,
    headers: {
      ...headers,
      ...(access_token ? { authorization: access_token } : {}),
    },
  };
});

const error_link = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      console.error("GraphQL error", message);
      const code = extensions && extensions.code;

      switch (code) {
        case "UNAUTHENTICATED":
          sign_out(client);
          break;
        case "FORBIDDEN":
          break;
        case "BAD_USER_INPUT":
          break;
        case "INTERNAL_SERVER_ERROR":
          break;
        default:
          break;
      }
    });
  }

  if (networkError) {
    console.error("Network error", networkError);
    if (networkError.statusCode === 401) {
      sign_out(client);
    }
  }
});

const custom_fetch = (uri, options) => {
  return fetch(uri, options).then(async (response) => {
    const json = await response.clone().json();

    const is_auth_error =
      json &&
      json.errors &&
      json.errors.length &&
      json.errors.some(
        ({ extensions }) => extensions && extensions.code === "UNAUTHENTICATED",
      );
    if (!is_auth_error) {
      return response;
    }

    return fetch_access_token().then((access_token) => {
      if (!access_token) {
        return response;
      }

      options.headers.authorization = access_token;
      return fetch(uri, options);
    });
  });
};

const http_link = new HttpLink({
  uri: SERVER_URL,
  fetch: custom_fetch,
});

const link = ApolloLink.from([auth_link, error_link, http_link]);
const cache = new InMemoryCache({ freezeResults: true });

export const client = new ApolloClient({ link, cache });
