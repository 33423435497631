import React from "react";
import { GridContainer, GridItem } from "../Grid";
import { Card, CardFooter, CardHeader, CardBody } from "../Card";
import classNames from "classnames";

import Button from "../Button";

const Message = ({ title, text, button_text, url, classes, history }) => {
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={5} md={3}>
          <Card>
            <CardHeader
              className={classNames(classes.cardHeader, classes.textCenter)}
              color="success"
            >
              <h4 className={classes.cardTitle}>{title}</h4>
            </CardHeader>
            <CardBody>{text}</CardBody>
            {button_text && (
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  onClick={() => history.push(url)}
                  color="success"
                  simple
                  size="lg"
                  block
                >
                  {button_text}
                </Button>
              </CardFooter>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Message;
