import gql from "graphql-tag";

export const USER_FIELDS = gql`
  fragment user_fields on User {
    id
    name
    username
    phone_number
    ssn
    email
    address_zip
    address_city
    address_streetwise
    org_id
    org_name
    org_address_zip
    org_address_city
    org_address_streetwise
    role
    super
    is_active_user
    is_active_mqtt
    is_email_verified
  }
`;

export const COLLAR_FIELDS = gql`
  fragment collar_fields on Collar {
    id
    name
    key
    description
    note
    user {
      id
    }
    vendor {
      id
    }
  }
`;

export const VENDOR_FIELDS = gql`
  fragment vendor_fields on Vendor {
    id
    name
    description
  }
`;

export const GROUP_FIELDS = gql`
  fragment group_fields on Group {
    id
    name
    description
    owner {
      id
      name
    }
  }
`;

export const GROUP_COLLARS_FRAGMENT = gql`
  fragment group_collars on Group {
    id
    collars {
      id
    }
  }
`;

export const GROUP_USERS_FRAGMENT = gql`
  fragment group_users on Group {
    id
    members {
      id
    }
  }
`;

export const EXPORT_METRIC_FIELDS = gql`
  fragment export_metric_fields on HumanReadableExportMetric {
    field
    label
    description
  }
`;

export const EXPORT_TEMPLATE_FIELDS = gql`
  fragment export_template_fields on ExportTemplate {
    id
    name
    description
    metrics {
      field
      ...export_metric_fields
    }
  }
  ${EXPORT_METRIC_FIELDS}
`;
