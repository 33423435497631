/**
 * We've run into issues where users end up in a redirect loop due to invalid
 * tokens in localStorage. This page is a fail-safe that allows less tech-savvy
 * users to easily clear localStorage.
 */

import React from "react";

export default function ClearStorage() {
  localStorage.clear();
  setTimeout(() => {
    location.href = "/";
  }, 2000);
  return <h1>Local storage cleared</h1>;
}
