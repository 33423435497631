import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Category from "@material-ui/icons/Category";

import { GridContainer, GridItem } from "../components/Grid";
import { Card, CardBody, CardIcon, CardHeader } from "../components/Card";

import { cardTitle } from "../assets/jss";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as queries from "../constants/queries";

import MaterialTable from "../components/MaterialTable";
import * as mutations from "../constants/mutations";
import * as fragments from "../constants/fragments";

import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

import { useToasts } from "react-toast-notifications";
import VendorCollars from "./detailPanels/vendorCollars";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

export default function VendorsTable() {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const onError = (error) =>
    addToast(error.toString(), { appearance: "error" });

  const { data: get_me_result } = useQuery(queries.GET_ME, { onError });
  const me = get_me_result?.me;
  const is_admin = me?.role === "ADMIN";

  const { loading, data, refetch } = useQuery(queries.GET_VENDORS, { onError });

  const table_data =
    (data && data.vendors && data.vendors.map((obj) => ({ ...obj }))) || [];
  table_data.forEach((vendor) => {
    vendor.allow_edit = is_admin;
  });

  const [create_vendor] = useMutation(mutations.CREATE_VENDOR, {
    update: (cache, { data: { create_vendor } }) => {
      cache.writeFragment({
        id: `${create_vendor.__typename}:${create_vendor.id}`,
        fragment: fragments.VENDOR_FIELDS,
        data: create_vendor,
      });
    },
    onCompleted: refetch,
    onError,
  });

  const [update_vendor] = useMutation(mutations.UPDATE_VENDOR, {
    update: (cache, { data: { update_vendor } }) => {
      cache.writeFragment({
        id: `${update_vendor.__typename}:${update_vendor.id}`,
        fragment: fragments.VENDOR_FIELDS,
        data: update_vendor,
      });
    },
    onCompleted: refetch,
    onError,
  });

  const [delete_vendor] = useMutation(mutations.DELETE_VENDOR, {
    update: (cache, { data: { delete_vendor } }) => {
      cache.writeFragment({
        id: `${delete_vendor.__typename}:${delete_vendor.id}`,
        fragment: fragments.VENDOR_FIELDS,
        data: null,
      });
    },
    onCompleted: refetch,
    onError,
  });

  const table_props = {
    data: table_data,
    columns: [
      {
        title: t("Name"),
        field: "name",
        searchable: true,
        validate: (rowData) =>
          !rowData.name
            ? { isValid: false, helperText: t("Provide a name") }
            : true,
      },
      {
        title: t("Description"),
        field: "description",
        searchable: true,
      },
    ],
    editable: {
      isEditable: (row) => row.allow_edit,
      isDeletable: (row) => row.allow_edit,
      externalAddButton: { text: t("Add vendor") },
      onRowAdd: is_admin
        ? (row) => {
            return create_vendor({ variables: { ...row } });
          }
        : null,
      onRowUpdate: (row) => {
        return update_vendor({ variables: { ...row } });
      },
      onRowDelete: (row) => {
        return delete_vendor({ variables: { id: row.id } });
      },
    },
    detailPanel: [
      () => ({
        isFreeAction: true,
        disabled: false,
        tooltip: t("Show collars"),
        icon: () => <ArrowRight />,
        iconOpen: () => <ArrowDropDown />,
        render: (row) => <VendorCollars id={row.id} />,
      }),
    ],
    options: {
      search: true,
      selection: false,
      actionsColumnIndex: -1,
      toolbar: true,
      showTitle: false,
      paging: false,
      detailPanelType: "single",
    },
    isLoading: loading,
    onRowClick: (_event, _row, toggleDetailPanel) => toggleDetailPanel(),
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              {" "}
              <Category />{" "}
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{t("Vendors")}</h4>
          </CardHeader>

          <CardBody>
            <Typography color="textSecondary">
              {t("VendorsPageDescription")}
            </Typography>
            <br />
            <MaterialTable {...table_props} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
