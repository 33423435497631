import React from "react";
import Button from "../Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";

export default function AlertDialog({
  open,
  set_open,
  title,
  content,
  dialogProps,
  Actions,
}) {
  const { t } = useTranslation();
  const handle_close = () => set_open && set_open(false);

  return (
    <div>
      <Dialog
        {...dialogProps}
        open={open}
        onClose={handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

        <DialogContent>{content}</DialogContent>

        <DialogActions>
          {Actions ? (
            <Actions />
          ) : (
            <Button size="sm" onClick={handle_close} color="success">
              {" "}
              {t("Close")}{" "}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
