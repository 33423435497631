import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import Person from "@material-ui/icons/Person";
import Button from "../Button";
import { useTranslation } from "react-i18next";

import { headerLinksStyle } from "../../assets/jss/components";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { GET_ME } from "constants/queries";

const useStyles = makeStyles(headerLinksStyle);

export default function NavbarLinks() {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data: profile_data } = useQuery(GET_ME);
  const me = profile_data?.me;

  const [open_profile, set_open_profile] = React.useState(null);

  const handleClickProfile = (event) =>
    set_open_profile(
      open_profile && open_profile.contains(event.target)
        ? null
        : event.currentTarget,
    );
  const handleCloseProfile = () => set_open_profile(null);

  return (
    <div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          simple={!(window.innerWidth > 959)}
          aria-owns={open_profile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          {me?.name}
        </Button>
        <Poppers
          open={Boolean(open_profile)}
          anchorEl={open_profile}
          transition
          disablePortal
          placement={"bottom-end"}
          className={classNames(
            { [classes.popperClose]: !open_profile },
            classes.popperNav,
          )}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <Link to={`/admin/user`}>
                      {" "}
                      <MenuItem
                        onClick={handleCloseProfile}
                        className={classes.dropdownItem}
                      >
                        {" "}
                        {t("Profile")}{" "}
                      </MenuItem>{" "}
                    </Link>

                    <Divider light />

                    <Link to={"/signout"}>
                      {" "}
                      <MenuItem
                        onClick={handleCloseProfile}
                        className={classes.dropdownItem}
                      >
                        {" "}
                        {t("Sign out")}{" "}
                      </MenuItem>{" "}
                    </Link>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
