const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";

const token = {
  read_access_token: () => localStorage.getItem(ACCESS_TOKEN),
  update_access_token: (token) => localStorage.setItem(ACCESS_TOKEN, token),

  read_refresh_token: () => localStorage.getItem(REFRESH_TOKEN),
  update_refresh_token: (token) => localStorage.setItem(REFRESH_TOKEN, token),

  remove_all: () => localStorage.clear(),
};

export default token;
