import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import GroupWorkSharp from "@material-ui/icons/GroupWorkSharp";
import PersonAdd from "@material-ui/icons/PersonAdd";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { GridContainer, GridItem } from "../components/Grid";
import { Card, CardBody, CardIcon, CardHeader } from "../components/Card";
import Button from "../components/Button";

import { cardTitle } from "../assets/jss";
import { useMutation, useQuery } from "@apollo/react-hooks";

import MaterialTable from "../components/MaterialTable";
import * as mutations from "../constants/mutations";
import * as queries from "../constants/queries";
import * as fragments from "../constants/fragments";

import GroupCollars from "./detailPanels/groupCollars";
import GroupUsers from "./detailPanels/groupUsers";
import EditGroupUsers from "./detailPanels/editGroupUsers";

import PeopleOutlineSharpIcon from "@material-ui/icons/PeopleOutlineSharp";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import ListIcon from "@material-ui/icons/List";
import { useToasts } from "react-toast-notifications";
import { Typography } from "@material-ui/core";
import CustomInput from "components/Input";
import is_valid_email from "constants/emailValidation";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

export default function GroupsTable({ is_admin }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const tableRef = useRef(null);
  const onError = (error) => {
    let message = error.toString();
    if (message.match(/That user is already a member of the group/)) {
      message = t("That user is already a member of the group");
    }
    addToast(message, { appearance: "error" });
  };

  const onSuccess = (message) =>
    addToast(message.toString(), { appearance: "success" });

  const { loading, data, refetch } = useQuery(queries.GET_GROUPS, { onError });

  const [invite_dialog_open, set_invite_dialog_open] = React.useState(false);
  const [email, set_email] = React.useState("");
  const [group_id, set_group_id] = React.useState(null);

  const maxGroupNameLength = 26;

  const [invite_to_group] = useMutation(mutations.INVITE_TO_GROUP, {
    onCompleted: (data) => {
      closeInviteDialog();
      onSuccess(
        `${t("Your invitation has been sent to")} ${
          data?.invite_to_group?.email
        }`,
      );
    },
    onError,
  });

  const closeInviteDialog = () => set_invite_dialog_open(false);

  const table_data =
    (data &&
      data.groups &&
      data.groups.map((group) => ({
        ...group,
      }))) ||
    [];
  table_data.forEach(
    (group) => (group.allow_edit = is_admin || group.owner.id === data?.me?.id),
  );

  const [create_group] = useMutation(mutations.CREATE_GROUP, {
    update: (cache, { data: { create_group } }) => {
      cache.writeFragment({
        id: `${create_group.__typename}:${create_group.id}`,
        fragment: fragments.GROUP_FIELDS,
        data: create_group,
      });
    },
    onCompleted: refetch,
    onError,
  });

  const [update_group] = useMutation(mutations.UPDATE_GROUP, {
    update: (cache, { data: { update_group } }) => {
      cache.writeFragment({
        id: `${update_group.__typename}:${update_group.id}`,
        fragment: fragments.GROUP_FIELDS,
        data: update_group,
      });
    },
    onCompleted: refetch,
    onError,
  });

  const [delete_group] = useMutation(mutations.DELETE_GROUP, {
    update: (cache, { data: { delete_group } }) => {
      cache.writeFragment({
        id: `${delete_group.__typename}:${delete_group.id}`,
        fragment: fragments.GROUP_FIELDS,
        data: null,
      });
    },
    onCompleted: refetch,
    onError,
  });

  const table_props = {
    data: table_data,
    tableRef,
    actions: [
      {
        icon: PersonAdd,
        tooltip: t("Invite user"),
        onClick: (event, rowData) => {
          // Clear and show the invite dialog:
          set_group_id(rowData.id);
          set_email("");
          set_invite_dialog_open(true);
        },
      },
    ],
    localization: {
      body: {
        deleteTooltip: t("Remove group"),
        addTooltip: t("Create new group"),
        editTooltip: t("Edit group"),
        editRow: {
          deleteText: t("Are you sure you want to delete this group?"),
          saveTooltip: t("Confirm"),
        },
      },
    },
    columns: [
      {
        title: t("Group name"),
        field: "name",
        searchable: true,
        validate: (rowData) => {
          if (!rowData.name) {
            return { isValid: false, helperText: t("Provide a name") };
          } else if (rowData.name.length > maxGroupNameLength) {
            return { isValid: false, helperText: t("The name is too long") };
          } else {
            return true;
          }
        },
      },
      {
        title: t("Owner"),
        field: "owner.id",
        initialEditValue: data?.me.id,
        ...(is_admin ? {} : { render: (group) => group?.owner?.name || "" }),
        lookup: data?.groups.reduce((lookup, group) => {
          group.members.forEach((user) => {
            lookup[user.id] = user.name;
          });
          return lookup;
        }, {}),
        editable: is_admin ? "always" : "never",
        searchable: true,
      },
      {
        title: t("Description"),
        field: "description",
        searchable: true,
      },
    ],
    editable: {
      isEditable: (row) => row.allow_edit,
      isDeletable: (row) => row.allow_edit,
      externalAddButton: { text: t("Create group") },
      onRowAdd: (row) => {
        return create_group({
          variables: { ...row, ownerId: row["owner.id"] || row.owner.id },
        });
      },
      onRowUpdate: (row) => {
        return update_group({
          variables: { ...row, ownerId: row["owner.id"] || row.owner.id },
        });
      },
      onRowDelete: (row) => {
        return delete_group({ variables: { id: row.id } });
      },
    },
    detailPanel: [
      () => ({
        // Users panel
        isFreeAction: true,
        disabled: false,
        icon: () => <PeopleOutlineSharpIcon />,
        openIcon: () => <PeopleOutlineSharpIcon />,
        tooltip: t("Show group members"),
        render: (row) => <GroupUsers id={row.id} />,
      }),
      () => ({
        // Collars panel
        isFreeAction: true,
        disabled: false,
        icon: () => <GpsFixedIcon />,
        openIcon: () => <GpsFixedIcon />,
        tooltip: t("Show collars"),
        render: (row) => <GroupCollars id={row.id} />,
      }),
    ],
    options: {
      search: true,
      selection: false,
      actionsColumnIndex: -1,
      toolbar: true,
      showTitle: false,
      paging: false,
      detailPanelType: "single",
    },
    isLoading: loading,
    onRowClick: (_event, _row, toggleDetailPanel) => toggleDetailPanel(),
  };

  // For admins only: Add another detail panel for managing group users.
  if (is_admin) {
    let createDetailPanel;

    /**
     * A bit of a hack:
     * Whenever a group is edited via the EditGroupUsers panel, that detail panel
     * would close. This function immediately reopens that detail panel.
     */
    const onGroupMembersEdited = (group_id) => {
      let index = 0;
      for (; index < table_data.length; index++) {
        if (table_data[index].id === group_id) {
          break;
        }
      }
      tableRef.current.onToggleDetailPanel([index], (rowData) => {
        return createDetailPanel().render(rowData);
      });
    };

    createDetailPanel = () => ({
      isFreeAction: true,
      disabled: false,
      icon: () => <ListIcon />,
      openIcon: () => <ListIcon />,
      tooltip: t("Edit group members"),
      render: (row) => (
        <EditGroupUsers
          id={row.id}
          onGroupMembersEdited={onGroupMembersEdited}
        />
      ),
    });

    table_props.detailPanel.push(createDetailPanel);
  }

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              {" "}
              <GroupWorkSharp />{" "}
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{t("Groups")}</h4>
          </CardHeader>
          <CardBody>
            <Typography paragraph color="textSecondary">
              {t("GroupsPageParagraph1")}
            </Typography>
            <Typography paragraph color="textSecondary">
              {t("GroupsPageParagraph2")}
            </Typography>
            <Typography paragraph color="textSecondary">
              {t("GroupsPageParagraph3")}
            </Typography>
            <Typography paragraph color="textSecondary">
              {t("GroupsPageParagraph4")}
            </Typography>
            <br />
            <MaterialTable {...table_props} />

            <Dialog open={invite_dialog_open} onClose={closeInviteDialog}>
              <DialogTitle>{t("Send an invitation")}</DialogTitle>
              <DialogContent>
                <DialogContentText>{t("EnterInviteeEmail")}</DialogContentText>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  errorMessage={t("Must be a valid Email address")}
                  labelText={t("Email")}
                  inputProps={{
                    error: email !== "" && !is_valid_email(email),
                    type: "email",
                    autoComplete: "off",
                    onChange: (event) => set_email(event.target.value),
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    invite_to_group({ variables: { id: group_id, email } });
                  }}
                  disabled={!is_valid_email(email)}
                  color="success"
                >
                  {t("Send")}
                </Button>
                <Button onClick={closeInviteDialog}>{t("Cancel")}</Button>
              </DialogActions>
            </Dialog>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
