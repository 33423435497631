import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as queries from "../../constants/queries";
import * as mutations from "../../constants/mutations";
import * as fragments from "../../constants/fragments";
import { useToasts } from "react-toast-notifications";
import SubTable from "../../components/SubTable";
import PeopleOutlineSharpIcon from "@material-ui/icons/PeopleOutlineSharp";

export default function GroupUsersTable({ id }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const onError = (error) =>
    addToast(error.toString(), { appearance: "error" });

  const { loading, data } = useQuery(queries.GET_GROUP_USERS, {
    variables: { id },
    onError,
  });
  const isAdmin = data?.me.role === "ADMIN";

  const isMyGroup =
    data &&
    data.group &&
    data.group.owner &&
    data.group.owner.id === data?.me.id;
  const mayEditGroup = isAdmin || isMyGroup;

  const isDeletable = (row) => {
    const isGroupOwner = row.id === data.group.owner.id;
    const isMe = row.id === data.me.id;
    return !isGroupOwner && (mayEditGroup || isMe);
  };

  const groupUsers =
    (data &&
      data.group &&
      data.group.members.map((member) => ({ ...member }))) ||
    [];

  const table_data = groupUsers;

  const [delete_users_from_group] = useMutation(
    mutations.DELETE_USERS_FROM_GROUP,
    {
      update: (
        cache,
        {
          data: {
            delete_users_from_group: { id, users, __typename },
          },
        },
      ) => {
        const data = cache.readFragment({
          id: `${__typename}:${id}`,
          fragment: fragments.GROUP_USERS_FRAGMENT,
        });
        cache.writeFragment({
          id: `${__typename}:${id}`,
          fragment: fragments.GROUP_USERS_FRAGMENT,
          data: { ...data, users },
        });
      },
      onError,
    },
  );

  const table_props = {
    title: t("Group members"),
    titleIcon: PeopleOutlineSharpIcon,
    data: table_data,
    localization: {
      body: {
        deleteTooltip: t("Exclude from group"),
        editRow: {
          deleteText: t(
            "Are you sure you want to exclude this user from the group?",
          ),
          saveTooltip: t("Confirm"),
        },
      },
    },
    columns: [
      {
        title: t("Name"),
        field: "name",
        searchable: true,
        validate: (rowData) =>
          !rowData.name
            ? { isValid: false, helperText: t("Provide a group name") }
            : true,
        render: (row) => {
          return (
            <span>
              {row.name}
              {row.id === data?.group.owner.id && (
                <strong style={{ marginLeft: 5 }}>({t("Owner")})</strong>
              )}
            </span>
          );
        },
      },
    ],
    actions: [],
    editable: {
      isDeletable,
      isEditable: isDeletable,
      onRowDelete: (row) => {
        return delete_users_from_group({
          variables: { id, ids: [row.id] },
        });
      },
    },
    options: {
      sorting: true,
      search: true,
      actionsColumnIndex: -1,
      toolbar: true,
      showTitle: true,
      paging: false,
      headerStyle: { display: "none" },
    },
    isLoading: loading,
  };

  return <SubTable {...table_props}></SubTable>;
}
