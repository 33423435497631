import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import classNames from "classnames";

import Lock from "@material-ui/icons/Lock";

import { GridContainer, GridItem } from "../components/Grid";
import CustomInput from "../components/Input";
import Button from "../components/Button";
import { Card, CardFooter, CardBody, CardHeader } from "../components/Card";

import { signinStyle } from "../assets/jss/views";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import * as mutations from "../constants/mutations";
import { useToasts } from "react-toast-notifications";
import Message from "../components/Message";
import { useTranslation } from "react-i18next";
import token from "../constants/token";

const useStyles = makeStyles(signinStyle);

export default function ResetPassword() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [success, set_success] = React.useState(false);

  const { addToast } = useToasts();
  const onError = (error) =>
    addToast(error.toString(), { appearance: "error" });

  const url_params = new URLSearchParams(window.location.search);

  const reset_token = url_params.has("token") && url_params.get("token");

  const [password, set_password] = React.useState("");
  const [password_confirmation, set_password_confirmation] = React.useState("");

  const onCompleted = () => {
    set_password("");
    set_password_confirmation("");
    set_success(true);
  };

  const [reset_password, { loading }] = useMutation(mutations.RESET_PASSWORD, {
    variables: { password },
    onError,
    onCompleted,
  });
  const is_disabled =
    password !== password_confirmation || !password || loading;

  const onSubmit = (event) => {
    if (is_disabled) {
      return;
    }
    reset_password().catch(onError);
    token.update_access_token(reset_token);
    event.preventDefault();
  };

  const message_props = {
    title: t("SuccessHeader"),
    text: t("SuccessReset"),
    button_text: t("Signin"),
    url: "/auth/signin",
    classes,
    history,
  };

  return success ? (
    Message(message_props)
  ) : (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={5} lg={4}>
          <form className={classes.form} onSubmit={onSubmit}>
            <Card>
              <CardHeader
                className={classNames(classes.cardHeader, classes.textCenter)}
                color="success"
              >
                <h4 className={classes.cardTitle}>{t("Reset password")}</h4>
              </CardHeader>

              <CardBody>
                <CustomInput
                  labelText={t("New password")}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Lock className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    onChange: (event) => set_password(event.target.value),
                  }}
                />
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  labelText={t("Password confirmation")}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Lock className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    onChange: (event) =>
                      set_password_confirmation(event.target.value),
                  }}
                />
              </CardBody>

              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  type="submit"
                  disabled={is_disabled}
                  color="success"
                  simple
                  size="lg"
                  block
                >
                  {" "}
                  {t("Reset password")}{" "}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
