import { container, cardTitle, whiteColor, grayColor } from "../";

const signinStyle = {
  container: {
    ...container,
    zIndex: "4",
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHeader: {
    marginBottom: "20px",
  },
};

export default signinStyle;
