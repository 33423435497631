import React from "react";
import { homeStyle } from "../assets/jss/views";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(homeStyle);

export default function Home() {
  const { t } = useTranslation();
  const classes = useStyles();

  return <div className={classes.container}>{t("Home page...")}</div>;
}
