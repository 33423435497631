import React, { useReducer } from "react";
import { DatePicker } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Dialog from "components/Dialog";

const SELECT_START_DATE = "SELECT_START_DATE";
const SELECT_END_DATE = "SELECT_END_DATE";

const reducer = (state, action) => {
  switch (action.type) {
    case SELECT_START_DATE:
      return {
        ...state,
        startDate:
          action.date.valueOf() < state.endDate ? action.date : state.startDate,
      };
    case SELECT_END_DATE:
      return {
        ...state,
        endDate:
          action.date.valueOf() <= Date.now() ? action.date : state.endDate,
      };
    default:
      return state;
  }
};

const SelectDates = ({ isOpen, close, onSelected }) => {
  const today = new Date();
  const { t } = useTranslation();
  const [{ startDate, endDate }, dispatch] = useReducer(reducer, {
    startDate: new Date(today.getFullYear(), today.getMonth() - 1, 1),
    endDate: new Date(today.getFullYear(), today.getMonth(), 0),
  });

  return (
    <Dialog
      open={isOpen}
      Actions={() => (
        <>
          <Button type="button" color="transparent" onClick={close}>
            {t("Back")}
          </Button>
          <Button
            type="button"
            color="info"
            onClick={() => onSelected({ startDate, endDate })}
          >
            {t("Next")}
          </Button>
        </>
      )}
      content={
        <>
          <h4>{t("Choose a Date Range")}</h4>
          <DatePicker
            value={startDate}
            maxDate={endDate}
            format="d MMM yyyy"
            onChange={(date) =>
              dispatch({
                type: SELECT_START_DATE,
                date,
              })
            }
          />
          <DatePicker
            value={endDate}
            minDate={startDate}
            format="d MMM yyyy"
            maxDate={Date.now()}
            onChange={(date) =>
              dispatch({
                type: SELECT_END_DATE,
                date,
              })
            }
          />
        </>
      }
    />
  );
};

export default SelectDates;
