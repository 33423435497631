import {
  Signup,
  Signin,
  UserProfile,
  UsersTable,
  CollarsTable,
  ExportTable,
  GroupsTable,
  InvitationsTable,
  ForgotPassword,
  ResetPassword,
  VerifyEmail,
  VendorsTable,
  InstructionVideos,
  Home,
  ClearStorage,
} from "../views";

import Person from "@material-ui/icons/Person";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import GroupWorkSharpIcon from "@material-ui/icons/GroupWorkSharp";
import PeopleOutlineSharpIcon from "@material-ui/icons/PeopleOutlineSharp";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import EmailIcon from "@material-ui/icons/Email";
import MailIcon from "@material-ui/icons/Mail";
import CategoryIcon from "@material-ui/icons/Category";
import HomeIcon from "@material-ui/icons/Home";
import CloudDownload from "@material-ui/icons/CloudDownload";
import YouTubeIcon from "@material-ui/icons/YouTube";

const routes = [
  {
    name_key: "Profile",
    component: UserProfile,
    icon: Person,
    path: "/user",
    layout: "/admin",
  },
  {
    name_key: "Users",
    component: UsersTable,
    icon: PeopleOutlineSharpIcon,
    path: "/users",
    layout: "/admin",
    only_for_admins: true,
    admin_links: true,
    help_text: "Administer users",
  },
  {
    name_key: "My collars",
    component: CollarsTable,
    icon: GpsFixedIcon,
    path: "/collars",
    layout: "/admin",
    admin_links: true,
    help_text: "Administer your collars",
  },
  {
    name_key: "Export",
    component: ExportTable,
    icon: CloudDownload,
    path: "/export",
    layout: "/admin",
    admin_links: true,
    help_text: "Export collars",
  },
  {
    name_key: "Groups",
    component: GroupsTable,
    icon: GroupWorkSharpIcon,
    path: "/groups",
    layout: "/admin",
    admin_links: true,
    help_text: "Administer your groups",
  },
  {
    name_key: "My invitations",
    component: InvitationsTable,
    icon: MailIcon,
    path: "/invitations",
    layout: "/admin",
    admin_links: true,
    help_text: "Respond to group invitations",
  },
  {
    name_key: "Vendors",
    component: VendorsTable,
    icon: CategoryIcon,
    path: "/vendors",
    layout: "/admin",
    admin_links: true,
    help_text: "Show vendors",
  },
  {
    name_key: "Instruction videos",
    component: InstructionVideos,
    icon: YouTubeIcon,
    path: "/instruction_videos",
    layout: "/admin",
    admin_links: true,
    help_text: "Show instruction videos",
  },
  {
    name_key: "Home",
    component: Home,
    icon: HomeIcon,
    layout: "/auth",
    path: "/home",
    auth_links: true,
  },
  {
    name_key: "Signin",
    component: Signin,
    icon: Fingerprint,
    layout: "/auth",
    path: "/signin",
    auth_links: true,
  },
  {
    name_key: "Signup",
    component: Signup,
    icon: PersonAdd,
    layout: "/auth",
    path: "/signup",
    auth_links: true,
  },
  {
    name_key: "Forgot password",
    component: ForgotPassword,
    icon: LockIcon,
    layout: "/auth",
    path: "/forgot_password",
    auth_links: true,
  },
  {
    name_key: "Reset password",
    component: ResetPassword,
    icon: LockOpenIcon,
    layout: "/auth",
    path: "/reset_password",
  },
  {
    name_key: "Verify email",
    component: VerifyEmail,
    icon: EmailIcon,
    layout: "/auth",
    path: "/verify_email",
  },
  {
    name_key: "Clear storage",
    component: ClearStorage,
    icon: EmailIcon,
    layout: "/auth",
    path: "/clear",
  },
];

export default routes;
