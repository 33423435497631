import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { footerStyle } from "../../assets/jss/components";
import { useTranslation } from "react-i18next";
import { LANGUAGE } from "constants/localStorage";
import styled from "styled-components";

const useStyles = makeStyles(footerStyle);

const FooterButton = styled.button`
  border: none;
  background: inherit;
  color: ${({ selected }) => (selected ? "#4caf50" : "inherit")};

  :hover {
    cursor: ${({ selected }) => (selected ? "inherit" : "pointer")};
  }
`;

export default function Footer() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const setLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem(LANGUAGE, language);
  };

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="#home" className={classes.block}>
                {t("Home")}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="#company" className={classes.block}>
                {t("Company")}
              </a>
            </ListItem>
          </List>
        </div>
        <div className={classes.right} style={{ paddingTop: 0 }}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <FooterButton
                className={classes.block}
                type="button"
                selected={i18n.language === "sv"}
                onClick={() => setLanguage("sv")}
              >
                Svenska
              </FooterButton>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <FooterButton
                className={classes.block}
                type="button"
                selected={i18n.language === "en"}
                onClick={() => setLanguage("en")}
              >
                English
              </FooterButton>
            </ListItem>
          </List>
        </div>
      </div>
    </footer>
  );
}
