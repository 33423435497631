import React, { useReducer } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import CloudDownload from "@material-ui/icons/CloudDownload";

import SelectCsvMetrics from "../components/SelectCsvMetrics";

import SelectDates from "components/SelectDates";
import DownloadReport from "components/DownloadCollarReport";

import { GridContainer, GridItem } from "../components/Grid";
import { Card, CardBody, CardIcon, CardHeader } from "../components/Card";

import { cardTitle } from "../assets/jss";
import { useQuery } from "@apollo/react-hooks";

import * as queries from "../constants/queries";

import MaterialTable from "../components/MaterialTable";

import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { buttonStyle } from "assets/jss/components";
import { Typography } from "@material-ui/core";

const SELECTING_COLLARS = "SELECTING_COLLARS";
const SELECTING_CSV_METRICS = "SELECTING_CSV_METRICS";
const SELECTING_DATES = "SELECTING_DATES";
const READY_TO_DOWNLOAD = "READY_TO_DOWNLOAD";
const ADDING_FILENAME = "ADDING_FILENAME";

const START_EXPORTING = "START_EXPORTING";
const SELECT_COLLARS = "SELECT_COLLARS";
const UPDATE_COLLAR_SELECTION = "UPDATE_COLLAR_SELECTION";
const SELECT_CSV_METRICS = "SELECT_CSV_METRICS";
const SELECT_DATES = "SELECT_DATES";
const ADD_FILENAME = "ADD_FILENAME";

const styles = {
  ...buttonStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

const reducer = (state, action) => {
  switch (action.type) {
    case START_EXPORTING:
      return { ...state, exportingState: SELECTING_COLLARS };
    case SELECT_COLLARS:
      return {
        ...state,
        exportingState: SELECTING_CSV_METRICS,
        collarsToExport: [...action.selectedCollars].map((c) => ({
          ...c,
          tableData: { checked: false },
        })),
      };
    case UPDATE_COLLAR_SELECTION:
      return {
        ...state,
        collarsToExport: [...action.selectedCollars].map((c) => ({
          ...c,
          tableData: { checked: false },
        })),
      };
    case SELECT_CSV_METRICS:
      return {
        ...state,
        exportingState: SELECTING_DATES,
        selectedCsvMetrics: action.selectedCsvMetrics,
      };
    case SELECT_DATES:
      return {
        ...state,
        exportingState: ADDING_FILENAME,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case ADD_FILENAME:
      return {
        ...state,
        exportingState: READY_TO_DOWNLOAD,
      };
    default:
      return state;
  }
};

export default function ExportTable() {
  const [
    { startDate, endDate, exportingState, collarsToExport, selectedCsvMetrics },
    dispatch,
  ] = useReducer(reducer, {
    exportingState: SELECTING_COLLARS,
    collarsToExport: [],
    selectedCsvMetrics: [],
  });

  const { t } = useTranslation();
  const { addToast } = useToasts();
  const onError = (error) => {
    const message = error.message || String(error);
    addToast(message, { appearance: "error" });
  };

  const { data: get_me_result } = useQuery(queries.GET_ME, { onError });

  const me = get_me_result?.me;
  const is_admin = me?.role === "ADMIN";

  const { loading, data } = useQuery(
    is_admin ? queries.GET_COLLARS_AND_USERS : queries.GET_COLLARS,
    { onError },
  );

  const table_data =
    (data &&
      data.collars &&
      data.collars.map((collar) => ({
        ...collar,
        user_id: collar?.user?.id,
        vendor_id: collar?.vendor?.id,
        tableData: {
          checked:
            exportingState === SELECTING_COLLARS &&
            collarsToExport.some((c) => c.id === collar.id),
        },
        allow_edit: true,
        allow_detail_panel: me?.id === collar.user_id || is_admin,
      }))) ||
    [];

  const classes = useStyles();
  const infoButtonClass = classNames(classes.button, classes.sm, classes.info);

  const table_props = {
    data: [SELECTING_CSV_METRICS, SELECTING_DATES].includes(exportingState)
      ? collarsToExport
      : table_data,
    actions: [
      exportingState === SELECTING_COLLARS
        ? {
            icon: (props) => {
              return (
                <div {...props} className={infoButtonClass}>
                  {t("Export")}
                </div>
              );
            },
            onClick: (_, selectedCollars) => {
              dispatch({
                type: SELECT_COLLARS,
                selectedCollars,
              });
            },
          }
        : null,
    ],
    columns: [
      {
        title: t("Collar Name"),
        field: "name",
        searchable: true,
        editable: is_admin ? "always" : "never",
      },
      {
        title: t("Owner"),
        field: "user_id",
        ...(is_admin ? {} : { render: (row) => row?.user?.name || "" }),
        searchable: true,
        hidden: !is_admin,
        lookup: is_admin
          ? data?.users?.reduce((acc, user) => {
              acc[user.id] = user.name;
              return acc;
            }, {})
          : null,
        initialEditValue: data?.me?.id,
        editable: is_admin ? "always" : "never",
      },
      {
        title: t("Key"),
        field: "key",
        searchable: true,
        initialEditValue: "",
        editable: is_admin ? "always" : "never",
      },
      {
        title: t("Vendor"),
        field: "vendor_id",
        ...(is_admin ? {} : { render: (row) => row?.vendor?.name || "" }),
        searchable: true,
        lookup: is_admin
          ? data?.vendors?.reduce((acc, vendor) => {
              acc[vendor.id] = vendor.name;
              return acc;
            }, {})
          : null,
        initialEditValue: Math.min(...(data?.vendors?.map((v) => v.id) || [])),
        editable: is_admin ? "always" : "never",
      },
      {
        title: t("Description"),
        field: "description",
        searchable: true,
        editable: is_admin ? "never" : "always",
      },
      {
        title: t("Note"),
        field: "note",
        searchable: true,
        editable: is_admin ? "never" : "always",
      },
    ],
    editable: {
      isEditable: (row) => row.allow_edit,
      isDeletable: (row) => row.allow_edit,
    },
    onSelectionChange: (rows) => {
      dispatch({ type: UPDATE_COLLAR_SELECTION, selectedCollars: rows });
    },
    options: {
      search: true,
      selection: exportingState === SELECTING_COLLARS,
      showSelectAllCheckbox: exportingState === SELECTING_COLLARS,
      actionsColumnIndex: -1,
      toolbar: true,
      showTitle: false,
      paging: false,
      detailPanelType: "single",
    },
    isLoading: loading,
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color={"success"} icon>
            <CardIcon color={"success"}>
              {" "}
              <CloudDownload />{" "}
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{t("Export")}</h4>
          </CardHeader>

          <CardBody>
            <Typography color="textSecondary">
              {t("ExportPageDescription")}
            </Typography>
            <br />
            <MaterialTable {...table_props} />
          </CardBody>
        </Card>
      </GridItem>
      <SelectCsvMetrics
        isOpen={exportingState === SELECTING_CSV_METRICS}
        close={() => dispatch({ type: START_EXPORTING })}
        is_admin={is_admin}
        onMetricsSelected={(metrics) => {
          dispatch({
            type: SELECT_CSV_METRICS,
            selectedCsvMetrics: metrics,
          });
        }}
      />
      <SelectDates
        isOpen={exportingState === SELECTING_DATES}
        close={() =>
          dispatch({ type: SELECT_COLLARS, selectedCollars: collarsToExport })
        }
        onSelected={({ startDate, endDate }) => {
          dispatch({
            type: SELECT_DATES,
            startDate,
            endDate,
          });
        }}
      />
      {exportingState === ADDING_FILENAME && (
        <DownloadReport
          isOpen={exportingState === ADDING_FILENAME}
          collars={collarsToExport}
          startDate={startDate}
          endDate={endDate}
          metrics={selectedCsvMetrics}
          close={() => dispatch({ type: START_EXPORTING })}
          done={() => dispatch({ type: START_EXPORTING })}
        />
      )}
    </GridContainer>
  );
}
