import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";

import * as mutations from "../constants/mutations";
import { useToasts } from "react-toast-notifications";
import { makeStyles } from "@material-ui/core/styles";
import { signinStyle } from "../assets/jss/views";
import { GridContainer, GridItem } from "../components/Grid";
import { Card, CardFooter, CardHeader } from "../components/Card";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Button from "../components/Button";
import Message from "../components/Message";
import token from "../constants/token";

const useStyles = makeStyles(signinStyle);

const VerifyEmail = () => {
  const { t } = useTranslation();
  const [success, set_success] = React.useState(false);

  const url_params = new URLSearchParams(window.location.search);
  const verify_token = url_params.has("token") && url_params.get("token");

  const classes = useStyles();
  const history = useHistory();

  const { addToast } = useToasts();

  const onError = (error) =>
    addToast(error.toString(), { appearance: "error" });
  const onCompleted = () => set_success(true);

  const [verify_email] = useMutation(mutations.VERIFY_EMAIL, {
    variables: { token: verify_token },
    onCompleted,
    onError,
  });

  const onClick = (event) => {
    if (!token) {
      onError(t("Token is missing."));
      return;
    }
    token.update_access_token(verify_token);
    verify_email().catch(onError);
    event.preventDefault();
  };

  const message_props = {
    title: t("SuccessHeader"),
    text: t("Your email has been verified"),
    button_text: t("Sign in"),
    url: "/auth/signin",
    classes,
    history,
  };

  return success ? (
    Message(message_props)
  ) : (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={5} lg={4}>
          <Card>
            <CardHeader
              className={classNames(classes.cardHeader, classes.textCenter)}
              color="success"
            >
              <h4 className={classes.cardTitle}>{t("Verify Email")}</h4>
            </CardHeader>

            <CardFooter className={classes.justifyContentCenter}>
              <Button onClick={onClick} color="success" simple size="lg" block>
                {" "}
                {t("Verify")}{" "}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default VerifyEmail;
