import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import GroupWorkSharpIcon from "@material-ui/icons/GroupWorkSharp";

import * as queries from "../../constants/queries";
import SubTable from "components/SubTable";

export default function UserMyGroupsTable({ id }) {
  const { t } = useTranslation();
  const { loading, data } = useQuery(queries.GET_USER_GROUPS, {
    variables: { id },
  });

  const table_data =
    (data &&
      data.user &&
      data.user.my_groups &&
      data.user.my_groups.map((group) => ({
        ...group,
        owner_name: group?.owner?.name,
      }))) ||
    [];

  const table_props = {
    title: t("Groups created by this user"),
    titleIcon: GroupWorkSharpIcon,
    data: table_data,
    columns: [
      { title: t("Group name"), field: "name" },
      { title: t("Owner"), field: "owner.name" },
      { title: t("Description"), field: "description" },
    ],
    options: {
      search: false,
      selection: false,
      actionsColumnIndex: -1,
      toolbar: true,
      showTitle: true,
      paging: false,
    },
    isLoading: loading,
  };

  return <SubTable {...table_props}></SubTable>;
}
