import React from "react";
import history from "./constants/history";
import {
  Router as RouterProvider,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import { Admin, Auth } from "./layouts";
import { useApolloClient } from "react-apollo";
import { sign_out } from "./apollo-client";
import token from "constants/token";

export const Routes = () => {
  const client = useApolloClient();
  const accessToken = token.read_access_token();
  return (
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} />
      <Route path="/signout" component={() => sign_out(client)} />
      <Redirect
        exact
        from="/"
        to={accessToken ? "/admin/user" : "/auth/signin"}
      />
    </Switch>
  );
};

const Router = () => {
  return (
    <RouterProvider history={history}>
      <Routes />
    </RouterProvider>
  );
};

export default Router;
