import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const itemStyle = {
  grid: {
    padding: "0 15px !important",
  },
};

const useStyles = makeStyles(itemStyle);

export default function GridItem(props) {
  const classes = useStyles();

  const { children, ...rest } = props;

  return (
    <Grid item {...rest} className={classes.grid}>
      {" "}
      {children}{" "}
    </Grid>
  );
}

GridItem.propTypes = {
  children: PropTypes.node,
};
