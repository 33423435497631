import { transition, container, blackColor, whiteColor, hexToRgb } from "../";

const authStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: { width: "100%" },
    overflow: "auto",
    position: "relative",
    float: "right",
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    ...transition,
    backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)",
    color: whiteColor,
  },
  content: {
    marginTop: "70px",
    padding: "33px 15px",
    minHeight: "calc(100vh - 180px)",
  },
  container,
  map: {
    marginTop: "70px",
  },
});

export default authStyle;
