import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import classNames from "classnames";

import Business from "@material-ui/icons/Business";
import Face from "@material-ui/icons/Face";
import Lock from "@material-ui/icons/Lock";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { GridContainer, GridItem } from "../components/Grid";
import CustomInput from "../components/Input";
import Button from "../components/Button";
import { Card, CardFooter, CardBody, CardHeader } from "../components/Card";

import { signinStyle } from "../assets/jss/views";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import * as mutations from "../constants/mutations";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useToasts } from "react-toast-notifications";
import Message from "../components/Message";
import Dialog from "../components/Dialog";
import { useTranslation } from "react-i18next";
import is_valid_email from "constants/emailValidation";

const useStyles = makeStyles(signinStyle);

export default function Signup() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const ssnPattern = "[0-9]{8}-[0-9]{4}";
  const passwordMinLength = 8;
  const usernameMinLength = 2;
  const usernameMaxLength = 24;
  const nameMinLength = 2;

  const { addToast } = useToasts();
  const onError = (error) => {
    let message = error.toString();
    if (message.match(/email.*not.*valid/i)) {
      message = t("The e-mail address is not valid");
    }
    if (message.match(/username.*exists/i)) {
      message = t("That username is already taken");
    }
    if (message.match(/ssn.*exists/i)) {
      message = t("The individual with that SSN is already registered");
    }
    if (message.match(/email.*exists/i)) {
      message = t("There already exists an account with that email address");
    }
    addToast(message, { appearance: "error" });
  };

  const [open, set_open] = React.useState(false);
  const [is_checked, set_is_checked] = React.useState(false);
  const [add_org, set_add_org] = React.useState(false);
  const [username, set_username] = React.useState("");
  const [name, set_name] = React.useState("");
  const [email, set_email] = React.useState("");
  const [email_confirmation, set_email_confirmation] = React.useState("");
  const [phone_number, set_phone_number] = React.useState("");
  const [ssn, set_ssn] = React.useState("");
  const [ssn_confirmation, set_ssn_confirmation] = React.useState("");
  const [password, set_password] = React.useState("");
  const [password_confirmation, set_password_confirmation] = React.useState("");
  const [org_name, set_org_name] = React.useState("");
  const [org_id, set_org_id] = React.useState("");
  const [org_address_streetwise, set_org_address_streetwise] = React.useState(
    "",
  );
  const [org_address_city, set_org_address_city] = React.useState("");
  const [org_address_zip, set_org_address_zip] = React.useState("");
  const [success, set_success] = React.useState(false);

  const onCompleted = () => {
    set_username("");
    set_name("");
    set_email("");
    set_email_confirmation("");
    set_password("");
    set_password_confirmation("");
    set_success(true);
  };

  const message_props = {
    title: t("SuccessHeader"),
    text: t("VerificationSent"),
    button_text: "",
    url: "",
    classes,
    history,
  };

  const dialog_props = {
    open,
    set_open: (open) => {
      set_open(open);
      history.goBack();
    },
    title: t(
      "Information on handling of personal data for the NomaTrack service",
    ),
    content: i18n.language === "sv" ? terms_sv : terms_en,
  };

  const [sign_up, { loading }] = useMutation(mutations.SIGN_UP, {
    variables: {
      username,
      name,
      email,
      password,
      ssn,
      phone_number,
      org_name,
      org_id,
      org_address_city,
      org_address_streetwise,
      org_address_zip,
    },
    onCompleted,
    onError,
  });

  const usernameError = username !== "" && !/^[a-z][a-z]+$/.test(username);

  const is_disabled =
    password !== password_confirmation ||
    !password ||
    !email ||
    email !== email_confirmation ||
    !ssn ||
    ssn !== ssn_confirmation ||
    !username ||
    usernameError ||
    !name ||
    loading ||
    !is_checked;

  const clearOrganizationFields = () => {
    set_org_id("");
    set_org_name("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (is_disabled) {
      onError(t("Please make sure to fill in all required fields correctly."));
      return;
    }

    sign_up().catch(console.error);
    event.preventDefault();
  };

  const organizationFields = () => (
    <>
      <CustomInput
        formControlProps={{ fullWidth: true }}
        labelText={t("Organization name")}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Business className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
          inputProps: {
            minLength: 2,
            maxLength: 24,
          },
          autoComplete: "off",
          onChange: (event) => set_org_name(event.target.value),
        }}
      />
      <CustomInput
        formControlProps={{ fullWidth: true }}
        labelText={t("Company registration number")}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <Business className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
          inputProps: { pattern: "[0-9]{6}-[0-9]{4}" },
          autoComplete: "off",
          placeholder: "XXXXXX-XXXX",
          onChange: (event) => set_org_id(event.target.value),
        }}
      />
      <CustomInput
        labelText={t("Organization address")}
        id="address_streetwise"
        formControlProps={{ fullWidth: true }}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircleIcon className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
          onChange: (event) => set_org_address_streetwise(event.target.value),
        }}
      />
      <GridContainer>
        <GridItem>
          <CustomInput
            labelText={t("ZIP Code")}
            id="address_zip"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              onChange: (event) => set_org_address_zip(event.target.value),
            }}
          />
        </GridItem>

        <GridItem>
          <CustomInput
            labelText={t("City")}
            id="address_city"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              onChange: (event) => set_org_address_city(event.target.value),
            }}
          />
        </GridItem>
      </GridContainer>
    </>
  );

  return success ? (
    Message(message_props)
  ) : (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={5} lg={4}>
          <form className={classes.form} onSubmit={onSubmit}>
            <Card>
              <CardHeader
                className={classNames(classes.cardHeader, classes.textCenter)}
                color="success"
              >
                <h4 className={classes.cardTitle}>{t("SignUpHeader")}</h4>
              </CardHeader>

              <CardBody>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: -24,
                    fontSize: "0.9em",
                  }}
                >
                  <span style={{ color: "darkred", marginRight: 5 }}>*</span>
                  {t("Required")}
                </div>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  required={true}
                  errorMessage={t(
                    "Must be at least 2 characters, lowercase letters only",
                  )}
                  labelText={t("Username")}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <AccountCircleIcon
                          className={classes.inputAdornmentIcon}
                        />
                      </InputAdornment>
                    ),
                    error: usernameError,
                    inputProps: {
                      minLength: usernameMinLength,
                      maxLength: usernameMaxLength,
                    },
                    autoComplete: "off",
                    onChange: (event) => set_username(event.target.value),
                  }}
                />
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  required={true}
                  errorMessage={t("Must be at least 2 characters")}
                  labelText={t("Full name")}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    error: name !== "" && name.length < nameMinLength,
                    inputProps: {
                      minLength: nameMinLength,
                    },
                    autoComplete: "off",
                    onChange: (event) => set_name(event.target.value),
                  }}
                />
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  required={true}
                  errorMessage={t(
                    "Social Security Number must have the format YYYYMMDD-XXXX",
                  )}
                  labelText={t("Social Security Number")}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    error: ssn !== "" && !ssn.match(ssnPattern),
                    inputProps: { pattern: "[0-9]{8}-[0-9]{4}" },
                    autoComplete: "off",
                    placeholder: t("YYYYMMDD-XXXX"),
                    onChange: (event) => set_ssn(event.target.value),
                  }}
                />
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  required={true}
                  errorMessage={t("Must match the field above")}
                  labelText={t("Confirm Social Security Number")}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    error: ssn !== ssn_confirmation,
                    inputProps: {
                      pattern: ssn,
                    },
                    autoComplete: "off",
                    placeholder: t("YYYYMMDD-XXXX"),
                    onChange: (event) =>
                      set_ssn_confirmation(event.target.value),
                  }}
                />
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  required={true}
                  errorMessage={t("Must be a valid Email address")}
                  labelText={t("Email")}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    error: email !== "" && !is_valid_email(email),
                    type: "email",
                    autoComplete: "off",
                    onChange: (event) => set_email(event.target.value),
                  }}
                />
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  required={true}
                  errorMessage={t("Must match the field above")}
                  labelText={t("Email confirmation")}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    error: email !== email_confirmation,
                    inputProps: {
                      pattern: email,
                    },
                    type: "email",
                    autoComplete: "off",
                    onChange: (event) =>
                      set_email_confirmation(event.target.value),
                  }}
                />
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  labelText={t("Phone number")}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Phone className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "tel",
                    autoComplete: "off",
                    onChange: (event) => set_phone_number(event.target.value),
                  }}
                />
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  labelText={t("Password")}
                  required={true}
                  errorMessage={t("Must be at least 8 characters")}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Lock className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    error:
                      password !== "" && password.length < passwordMinLength,
                    inputProps: {
                      required: true,
                      minLength: passwordMinLength,
                    },
                    onChange: (event) => set_password(event.target.value),
                  }}
                />
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  labelText={t("Password confirmation")}
                  required={true}
                  errorMessage={t("Must match the field above")}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Lock className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "password",
                    error: password !== password_confirmation,
                    inputProps: {
                      pattern: password,
                    },
                    autoComplete: "off",
                    onChange: (event) =>
                      set_password_confirmation(event.target.value),
                  }}
                />
                <FormControlLabel
                  label={<span>{t("Connect account to an organization")}</span>}
                  control={
                    <Checkbox
                      onClick={() => {
                        set_add_org(!add_org);
                        if (!add_org) {
                          clearOrganizationFields();
                        }
                      }}
                    />
                  }
                />
                {add_org && organizationFields()}
                <FormControlLabel
                  label={
                    <span>
                      {" "}
                      {t("I agree to the")}{" "}
                      <a href={"#terms"} onClick={() => set_open(true)}>
                        {t("terms and conditions")}
                      </a>
                      .
                    </span>
                  }
                  control={
                    <Checkbox
                      required
                      tabIndex={-1}
                      onClick={() => set_is_checked(!is_checked)}
                    />
                  }
                />
              </CardBody>

              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  type="submit"
                  disabled={is_disabled}
                  color="success"
                  simple
                  size="lg"
                  block
                >
                  {" "}
                  {t("Sign up")}{" "}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      <Dialog {...dialog_props}></Dialog>
    </div>
  );
}

const terms_en = (
  <div>Terms of use are currently only available in Swedish.</div>
);

const terms_sv = (
  <div>
    <p lang="sv-SE">
      Dálvvadis Ekonomisk Förening med organisationsnummer 769626-3008, behöver
      hantera en del Personuppgifter om dig som användare av NomaTracktjänsten.
    </p>
    <p lang="sv-SE">
      <strong>De uppgifter vi hanterar omfattar</strong>
    </p>
    <ol>
      <li>
        <p lang="sv-SE">Namn, adress, postnummer, postort</p>
      </li>
      <li>
        <p lang="sv-SE">Kontaktuppgifter: telefon, e-post.</p>
      </li>
      <li>
        <p lang="sv-SE">Personnummer, organisationsnummer</p>
      </li>
      <li>
        <p lang="sv-SE">Användarnamn</p>
      </li>
      <li>
        <p lang="sv-SE">Lösenord</p>
      </li>
      <li>
        <p lang="sv-SE">Roll i systemet (användare eller gruppadmin)</p>
      </li>
      <li>
        <p lang="sv-SE">De GPS-sändare som du använder</p>
        <ol type="a">
          <li>
            <p lang="sv-SE">Sändar-ID</p>
          </li>
          <li>
            <p lang="sv-SE">Information du sparat om sändaren (beskrivning).</p>
          </li>
        </ol>
      </li>
      <li>
        <p lang="sv-SE">Grupper som du skapat (gruppnamn).</p>
        <ol type="a">
          <li>
            <p lang="sv-SE">Gruppbenämningen som (gruppnamn).</p>
          </li>
          <li>
            <p lang="sv-SE">Beskrivning av gruppen</p>
          </li>
          <li>
            <p lang="sv-SE">De GPS-sändare som visas i gruppen (sändar-ID).</p>
          </li>
          <li>
            <p lang="sv-SE">
              De användare som bjudits in och tackat ja att ingå i gruppen.
            </p>
          </li>
          <li>
            <p lang="sv-SE">
              Sändar-ID på de GPS-sändare som gruppens användare har behörighet
              att se.
            </p>
          </li>
          <li>
            <p lang="sv-SE">
              De användare som bjudits in och tackat ja att ingå i gruppen.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <p lang="sv-SE">
          Grupper som andra bjudit in dig till och som du har tackat ja till.
        </p>
      </li>
    </ol>
    <p lang="sv-SE">
      <strong>Vi hanterar dina uppgifter för flera olika syften</strong>
    </p>
    <ol type="I">
      <li>
        <p lang="sv-SE">Tillhandahålla NomaTrack tjänsten till dig (1-9)</p>
      </li>
      <li>
        <p lang="sv-SE">
          Till affärsmässiga kontakter mellan Dálvvadis och dig som användare av
          utrustning och system.
        </p>
      </li>
    </ol>
    <p lang="sv-SE">
      <strong>De rättsliga grunderna är</strong>
    </p>
    <ol type="A">
      <li>
        <p lang="sv-SE">
          När du läst igenom och bockat för ditt samtycke (I+II) samtycker du
          att vi lagrar dessa uppgifter i samband med att du registrerar dig som
          användare av tjänsten.
        </p>
      </li>
    </ol>
    <p lang="sv-SE">
      <strong>Dina uppgifter behandlas</strong>
    </p>
    <ul>
      <li>
        <p lang="sv-SE">Server finns i Amsterdam</p>
      </li>
      <li>
        <p lang="sv-SE">Backupper lagras i Luleå (Mirror)</p>
      </li>
      <li>
        <p lang="sv-SE">
          3:e part autentiseringsuppgifter (GPS-positioneringstjänster) lagras
          på egen server (plain text format, ej krypterad)
        </p>
      </li>
      <li>
        <p lang="sv-SE">
          Fullständigt personnummer visas inte men lagras i server tillsammans
          med övriga tillhörande kontaktuppgifter.
        </p>
      </li>
    </ul>
    <p lang="sv-SE">
      <strong>Rättelse och Borttagning av uppgifter</strong>
    </p>
    <ul>
      <li>
        <p lang="sv-SE">Du kan se och rätta dina uppgifter i vår tjänst.</p>
      </li>
      <li>
        <p lang="sv-SE">
          Uppgifter som lagras raderas omgående ur tjänsten om du återkallar
          samtycket
        </p>
        <ul>
          <li>
            <p lang="sv-SE">
              Du kan själv ta bort dig som användare från tjänsten.
            </p>
          </li>
          <li>
            <p lang="sv-SE">Du kan själv avbryta deltagandet i grupper</p>
          </li>
          <li>
            <p lang="sv-SE">
              Du kan själv ta bort sändare som du inte vill ska visas i grupper
            </p>
          </li>
        </ul>
      </li>
    </ul>
    <p lang="sv-SE">
      <strong>Vid behandling av Tredje part</strong>
    </p>
    <ul>
      <li>
        <p>
          <em>Företag vi anlitar: </em>
          Vi vidtar alla rimliga legala, tekniska och organisatoriska åtgärder
          för att säkerställa att uppgifterna hanteras säkert och med en adekvat
          skyddsnivå vid överföring till eller delning med sådana tredje parter.
        </p>
      </li>
      <li>
        <p>
          <em>Myndigheter: </em>
          Vi kan komma att lämna nödvändig information till berörda myndigheter
          om vi är skyldiga att göra det enligt lag.
        </p>
      </li>
    </ul>

    <br />

    <p>
      <b>Kontaktuppgifter:</b>
      <br />
      Dálvvadis Ekonomisk Förening
      <br />
      Box 76
      <br />
      962 22 JOKKMOKK
      <br />
      Tel: 076-103 08 99
      <br />
      E-post: <a href="mailto: kansliet@dalvvadis.se">kansliet@dalvvadis.se</a>
    </p>
  </div>
);
