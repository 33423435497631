import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Menu from "@material-ui/icons/Menu";
import Button from "../Button";

import { authNavbarStyle } from "../../assets/jss/components";
import routes from "../../constants/routes";

const useStyles = makeStyles(authNavbarStyle);

export default function AuthNavbar(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { color } = props;
  const appBarColor = classNames({ [classes[color]]: color });

  const [is_drawer_open, set_drawer_open] = React.useState(false);
  const handleDrawerToggle = () => set_drawer_open(!is_drawer_open);

  const list = (
    <List className={classes.list}>
      {routes
        .filter((route) => route.auth_links)
        .map((route, i) => {
          const nav_link_classes = classNames(classes.navLink, {
            [classes.navLinkActive]:
              window.location.pathname === route.layout + route.path,
          });
          return (
            <ListItem className={classes.listItem} key={i}>
              <NavLink
                to={route.layout + route.path}
                className={nav_link_classes}
              >
                <route.icon className={classes.listItemIcon} />
                <ListItemText
                  primary={t(route.name_key)}
                  disableTypography={true}
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
    </List>
  );
  return (
    <AppBar
      position="static"
      className={classNames(classes.appBar, appBarColor)}
    >
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <Button href="#" className={classes.title} color="transparent">
            {" "}
            NomaTrack{" "}
          </Button>
        </div>

        <Hidden smDown>{list}</Hidden>
        <Hidden mdUp>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor="right"
              open={is_drawer_open}
              classes={{ paper: classes.drawerPaper }}
              onClose={handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
            >
              {list}
            </Drawer>
          </Hidden>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}
