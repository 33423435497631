import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import { inputStyle } from "../../assets/jss/components";
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles(inputStyle);

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    required = false,
    errorMessage,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
  } = props;

  if (inputProps.required === undefined) {
    inputProps.required = required;
  }

  const formControlClasses = classNames(
    formControlProps.className,
    classes.formControl,
  );
  const marginTop = classNames({ [classes.marginTop]: !labelText });

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      <InputLabel
        className={classes.labelRoot}
        style={{ fontSize: "1.2em" }}
        htmlFor={labelText}
        {...labelProps}
      >
        {" "}
        {labelText}{" "}
        {required ? <span style={{ color: "darkred" }}>*</span> : null}
      </InputLabel>

      <Input
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: classes.underline,
        }}
        id={id}
        {...inputProps}
        inputProps={{ ...inputProps.inputProps, id: labelText }}
      />

      {inputProps.error && errorMessage && (
        <FormHelperText>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
}

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
};
