import React from "react";
import { useTranslation } from "react-i18next";

import { GridContainer, GridItem } from "../components/Grid";
import { Card, CardBody, CardIcon, CardHeader } from "../components/Card";
import YouTubeIcon from "@material-ui/icons/YouTube";

import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "../assets/jss";
import { Typography } from "@material-ui/core";
import { INSTRUCTION_VIDEO_URL } from "constants/environment";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

const InstructionVideos = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              {" "}
              <YouTubeIcon />{" "}
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{t("Instruction videos")}</h4>
          </CardHeader>

          <CardBody>
            <Typography color="textSecondary">
              {t("InstructionVideoParagraph")}
            </Typography>

            <br />

            <Typography variant="h6">
              <a href={INSTRUCTION_VIDEO_URL}>{t("To instruction videos")}</a>
            </Typography>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default InstructionVideos;
