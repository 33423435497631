import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/Assignment";

import { GridContainer, GridItem } from "../components/Grid";
import { Card, CardBody, CardIcon, CardHeader } from "../components/Card";

import { cardTitle } from "../assets/jss";
import { useMutation, useQuery } from "@apollo/react-hooks";

import UserGroupsTable from "./detailPanels/userGroups";
import UserCollarsTable from "./detailPanels/userCollars";
import UserMyGroupsTable from "./detailPanels/userMyGroups";

import MaterialTable from "../components/MaterialTable";

import * as mutations from "../constants/mutations";
import * as queries from "../constants/queries";
import * as fragments from "../constants/fragments";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import GroupWorkSharpIcon from "@material-ui/icons/GroupWorkSharp";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

export default function UsersTable() {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const onError = (error) =>
    addToast(error.toString(), { appearance: "error" });

  const { loading, data, refetch } = useQuery(queries.GET_USERS, { onError });

  const me = (data && data.me) || {};
  const is_admin = me.role === "ADMIN";

  const table_data =
    (data && data.users && data.users.map((obj) => ({ ...obj }))) || [];
  table_data.forEach(
    (user) => (user.allow_edit = is_admin || me.id === user.id),
  );

  const [create_user] = useMutation(mutations.CREATE_USER, {
    update: (cache, { data: { create_user } }) => {
      cache.writeFragment({
        id: `${create_user.__typename}:${create_user.id}`,
        fragment: fragments.USER_FIELDS,
        data: create_user,
      });
    },
    onCompleted: refetch,
    onError,
  });

  const [update_user] = useMutation(mutations.UPDATE_USER, {
    update: (cache, { data: { update_user } }) => {
      cache.writeFragment({
        id: `${update_user.__typename}:${update_user.id}`,
        fragment: fragments.USER_FIELDS,
        data: update_user,
      });
    },
    onCompleted: refetch,
    onError,
  });

  const [delete_user] = useMutation(mutations.DELETE_USER, {
    refetchQueries: [
      {
        query: queries.GET_COLLARS,
        variables: { fetchPolicy: "cache-and-network" },
      },
      {
        query: queries.GET_GROUPS,
        variables: { fetchPolicy: "cache-and-network" },
      },
    ],
    update: (cache, { data: { delete_user } }) => {
      cache.writeFragment({
        id: `${delete_user.__typename}:${delete_user.id}`,
        fragment: fragments.USER_FIELDS,
        data: null,
      });
    },
    onCompleted: refetch,
    onError,
  });

  const table_props = {
    data: table_data,
    columns: is_admin
      ? [
          {
            title: t("Username"),
            field: "username",
            searchable: true,
            editable: "onAdd",
          },
          { title: t("Name"), field: "name", searchable: true },
          {
            title: t("Social Security Number"),
            field: "ssn",
            editable: "onAdd",
            searchable: true,
          },
          { title: t("Email"), field: "email", searchable: true },
          { title: t("Phone number"), field: "phone_number", searchable: true },
          {
            title: t("Role"),
            field: "role",
            lookup: { ADMIN: "ADMIN", USER: "USER" },
            initialEditValue: "USER",
          },
          {
            title: t("Super"),
            field: "super",
            initialEditValue: "0",
            lookup: Array(11)
              .fill(0)
              .map((a, i) => i)
              .reduce((acc, i) => {
                acc[i] = i;
                return acc;
              }, {}),
          },
          {
            title: "MQTT",
            field: "is_active_mqtt",
            type: "boolean",
            initialEditValue: true,
          },
          {
            title: t("Active"),
            field: "is_active_user",
            type: "boolean",
            initialEditValue: true,
          },
          {
            title: t("Email verified"),
            field: "is_email_verified",
            type: "boolean",
            initialEditValue: false,
            editable: is_admin ? "always" : "never",
          },
        ]
      : [
          { title: t("Name"), field: "name", searchable: true },
          { title: t("Email"), field: "email", searchable: true },
          {
            title: t("Role"),
            field: "role",
            lookup: { ADMIN: "ADMIN", USER: "USER" },
            initialEditValue: "USER",
          },
        ],
    editable: {
      isEditable: (row) => row.allow_edit,
      isDeletable: (row) => row.allow_edit,
      externalAddButton: { text: t("Add user") },
      onRowAdd: (row) => {
        if (!row?.ssn?.match(/[0-9]{8}-[0-9]{4}/)) {
          onError(
            t("Social Security Number must have the format YYYYMMDD-XXXX"),
          );
          return Promise.reject();
        }
        return create_user({
          variables: { ...row, ssn: row.ssn, super: Number(row.super) },
        });
      },
      onRowUpdate: (row) => {
        // The ssn value we have on the client side is obfuscated, ending with
        // "XXXX". Remove that value before updating.
        const { ssn: _ssn, ...rest } = row;
        return update_user({
          variables: { ...rest, super: Number(row.super) },
        });
      },

      onRowDelete: (row) => delete_user({ variables: { id: row.id } }),
    },
    detailPanel: [
      (row) => ({
        isFreeAction: true,
        disabled: !row.allow_edit,
        icon: () => <GpsFixedIcon />,
        openIcon: () => <GpsFixedIcon />,
        tooltip: t("Show collars"),
        render: (row) => <UserCollarsTable id={row.id} />,
      }),
      (row) => ({
        isFreeAction: true,
        disabled: !row.allow_edit,
        tooltip: row.allow_edit && t("Groups created by this user"),
        icon: () => <GroupWorkSharpIcon />,
        openIcon: () => <GroupWorkSharpIcon />,
        render: (row) => <UserMyGroupsTable id={row.id} />,
      }),
      (row) => ({
        isFreeAction: true,
        disabled: !row.allow_edit,
        icon: () => <GroupWorkSharpIcon />,
        openIcon: () => <GroupWorkSharpIcon />,
        tooltip: row.allow_edit && t("Groups where this user is a member"),
        render: (row) => <UserGroupsTable id={row.id} />,
      }),
    ],
    options: {
      search: true,
      selection: false,
      actionsColumnIndex: -1,
      toolbar: true,
      paging: false,
      showTitle: false,
      draggable: true,
      detailPanelType: "single",
    },
    isLoading: loading,
    onRowClick: (event, row, toggleDetailPanel) =>
      row.allow_edit && toggleDetailPanel(),
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              {" "}
              <Assignment />{" "}
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{t("Users")}</h4>
          </CardHeader>

          <CardBody>
            <MaterialTable {...table_props} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
