import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";
import GroupWorkSharpIcon from "@material-ui/icons/GroupWorkSharp";

import * as queries from "../../constants/queries";
import SubTable from "components/SubTable";

export default function UserGroupsTable({ id }) {
  const { t } = useTranslation();
  const { loading, data } = useQuery(queries.GET_USER_GROUPS, {
    variables: { id },
  });

  const table_data =
    (data && data.user && data.user.groups.map((obj) => ({ ...obj }))) || [];

  const table_props = {
    data: table_data,
    title: t("Groups where this user is a member"),
    titleIcon: GroupWorkSharpIcon,
    columns: [
      { title: t("Group name"), field: "name" },
      { title: t("Owner"), field: "owner.name" },
      { title: t("Description"), field: "description" },
    ],
    options: {
      search: false,
      selection: false,
      actionsColumnIndex: -1,
      toolbar: true,
      showTitle: true,
      paging: false,
      detailPanelType: "single",
    },
    isLoading: loading,
  };

  return <SubTable {...table_props}></SubTable>;
}
