import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as queries from "../../constants/queries";
import * as mutations from "../../constants/mutations";
import * as fragments from "../../constants/fragments";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AddBox from "@material-ui/icons/AddBox";
import { useToasts } from "react-toast-notifications";
import SubTable from "../../components/SubTable";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";

export default function GroupCollarsTable({ id }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const onError = (error) =>
    addToast(error.toString(), { appearance: "error" });

  const {
    data: { me },
  } = useQuery(queries.GET_ME, { onError });
  const is_admin = me.role === "ADMIN";

  const { loading, data, refetch } = useQuery(queries.GET_GROUP_COLLARS, {
    variables: { id },
    onError,
  });

  const is_my_group =
    data && data.group && data.group.owner && data.group.owner.id === me.id;

  const group_collars =
    (data && data.group && data.group.collars.map((obj) => ({ ...obj }))) || [];
  const group_collars_ids = group_collars.map((obj) => obj.id);
  group_collars.forEach((obj) => (obj.is_included = true));

  const collars =
    (data &&
      data.collars &&
      data.collars
        .filter((collar) => !group_collars_ids.includes(collar.id))
        .map((obj) => ({ ...obj }))) ||
    [];
  collars.forEach((obj) => (obj.is_included = false));

  const table_data = [].concat(collars, group_collars);

  table_data.forEach(
    (collar) =>
      (collar.allow_edit = is_admin || is_my_group || collar.user.id === me.id),
  );

  const [add_collars_to_group] = useMutation(mutations.ADD_COLLARS_TO_GROUP, {
    update: (
      cache,
      {
        data: {
          add_collars_to_group: { id, collars, __typename },
        },
      },
    ) => {
      const data = cache.readFragment({
        id: `${__typename}:${id}`,
        fragment: fragments.GROUP_COLLARS_FRAGMENT,
      });
      cache.writeFragment({
        id: `${__typename}:${id}`,
        fragment: fragments.GROUP_COLLARS_FRAGMENT,
        data: { ...data, collars },
      });
    },
    onCompleted: refetch,
    onError,
  });

  const [delete_collars_from_group] = useMutation(
    mutations.DELETE_COLLARS_FROM_GROUP,
    {
      update: (
        cache,
        {
          data: {
            delete_collars_from_group: { id, collars, __typename },
          },
        },
      ) => {
        const data = cache.readFragment({
          id: `${__typename}:${id}`,
          fragment: fragments.GROUP_COLLARS_FRAGMENT,
        });
        cache.writeFragment({
          id: `${__typename}:${id}`,
          fragment: fragments.GROUP_USERS_FRAGMENT,
          data: { ...data, collars },
        });
      },
      onCompleted: refetch,
      onError,
    },
  );

  const table_props = {
    title: t("Group collars"),
    titleIcon: GpsFixedIcon,
    data: table_data,
    columns: [
      {
        title: t("Included"),
        field: "is_included",
        type: "boolean",
        defaultSort: "desc",
      },
      { title: t("Collar Name"), field: "name", searchable: true },
      {
        title: t("Owner"),
        field: "user.name",
        searchable: true,
      },
      { title: t("Description"), field: "description", searchable: true },
    ],
    actions: [
      (rows) => ({
        isFreeAction: false,
        disabled: rows.some((row) => !row.allow_edit),
        tooltip: t("IncludeAllTooltip"),
        icon: () => <AddBox />,
        onClick: (evt, rows) =>
          add_collars_to_group({
            variables: { id, ids: rows.map((row) => row.id) },
          }),
      }),
      (rows) => ({
        isFreeAction: false,
        disabled: rows.some((row) => !row.allow_edit),
        tooltip: t("ExcludeAllTooltip"),
        icon: () => <DeleteOutline />,
        onClick: (evt, rows) =>
          delete_collars_from_group({
            variables: { id, ids: rows.map((row) => row.id) },
          }),
      }),
    ],
    options: {
      selection: true,
      showSelectAllCheckbox: true,
      sorting: true,
      search: true,
      actionsColumnIndex: -1,
      toolbar: true,
      showTitle: true,
      paging: false,
    },
    isLoading: loading,
  };

  return <SubTable {...table_props}></SubTable>;
}
