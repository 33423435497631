import React from "react";
import MaterialTable from "../../components/MaterialTable";

const SubTable = (props) => {
  // Wrap it in a div and add some styling to make it appear
  // as if lies slightly behind the main table.
  return (
    <div
      style={{
        backgroundColor: "#eee",
        boxShadow: "inset 2px 2px 4px -2px rgba(0,0,0,0.2)",
      }}
    >
      <MaterialTable
        {...props}
        style={{
          marginLeft: "20px",
          borderRadius: 0,
          backgroundColor: "rgba(253, 253, 253, 253)",
          boxShadow: "inset 0 2px 4px -2px rgba(0,0,0,0.2)",
        }}
      />
    </div>
  );
};

export default SubTable;
