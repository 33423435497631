import React, { useLayoutEffect } from "react";
import { Route, useHistory, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Navbar } from "../components/Navbar";
import Footer from "../components/Footer";
import routes from "../constants/routes";
import Sidebar from "../components/Sidebar";
import { adminStyle } from "../assets/jss/layouts";
import bgImage from "../assets/img/sidebar-4.jpg";
import logo from "../assets/img/reactlogo.png";
import { useQuery } from "@apollo/react-hooks";
import * as queries from "../constants/queries";

const useStyles = makeStyles(adminStyle);

export default function Admin({ ...rest }) {
  const classes = useStyles();
  const mainPanel = React.createRef();
  const history = useHistory();

  const [is_drawer_open, set_drawer_open] = React.useState(false);

  const handleDrawerToggle = () => set_drawer_open(!is_drawer_open);

  const { loading, data } = useQuery(queries.GET_INVITATIONS, {
    fetchPolicy: "cache-and-network",
  });

  const isAuthenticated = data && data.me;
  const is_admin = data?.me?.role === "ADMIN";
  const num_invitations = data?.me?.pending_invitations.length;

  useLayoutEffect(() => {
    if (!loading && !isAuthenticated) {
      history.push("/auth/signin");
    }
  }, [history, isAuthenticated, loading]);

  if (loading || !isAuthenticated) {
    return null;
  }

  const routes_to_show = routes.filter(
    (route) =>
      route.layout === "/admin" && (is_admin || !route.only_for_admins),
  );

  // Add notifications to the 'My invitations' route:
  routes_to_show.forEach((route) => {
    if (route.name_key === "My invitations") {
      route.notifications = num_invitations;
    }
  });

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes_to_show}
        logoText={"NomaTrack"}
        logo={logo}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={is_drawer_open}
        color={"blue"}
        {...rest}
      />

      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar handleDrawerToggle={handleDrawerToggle} {...rest} />

        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {routes_to_show.map((route, i) => {
                const RouteComponent = route.component;
                return (
                  <Route
                    path={route.layout + route.path}
                    render={() => <RouteComponent is_admin={is_admin} />}
                    key={i}
                  />
                );
              })}
            </Switch>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
