import { container, whiteColor } from "../";

const homeStyle = {
  container: {
    ...container,
    zIndex: "4",
    color: whiteColor,
  },
};

export default homeStyle;
