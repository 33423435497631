import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./assets/translation/en.json";
import sv from "./assets/translation/sv.json";

import { LANGUAGE } from "constants/localStorage";

const resources = {
  en,
  sv,
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem(LANGUAGE) || "sv",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
