import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import classNames from "classnames";

import { GridContainer, GridItem } from "../components/Grid";
import CustomInput from "../components/Input";
import Button from "../components/Button";
import { Card, CardFooter, CardBody, CardHeader } from "../components/Card";
import { useTranslation } from "react-i18next";

import { signinStyle } from "../assets/jss/views";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import * as mutations from "../constants/mutations";
import { useToasts } from "react-toast-notifications";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Message from "../components/Message";

const useStyles = makeStyles(signinStyle);

export default function ForgotPassword() {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const { addToast } = useToasts();
  const onError = (error) => {
    let message = error.toString();
    if (message.match(/no.*user/i)) {
      message = t("There exists no user with that e-mail or password");
    }
    addToast(message, { appearance: "error" });
  };

  const [login, set_login] = React.useState("");
  const [success, set_success] = React.useState(false);

  const onCompleted = () => {
    set_login("");
    set_success(true);
  };
  const [forgot_password, { loading }] = useMutation(
    mutations.FORGOT_PASSWORD,
    { variables: { login }, onCompleted, onError },
  );

  const is_disabled = !login || loading;

  const onSubmit = (event) => {
    if (is_disabled) {
      return;
    }

    forgot_password().catch(onError);

    event.preventDefault();
  };

  const message_props = {
    title: t("SuccessHeader"),
    text: t("ResetMessageSent"),
    button_text: "",
    url: "",
    classes,
    history,
  };

  return success ? (
    Message(message_props)
  ) : (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={5} lg={4}>
          <form className={classes.form} onSubmit={onSubmit}>
            <Card>
              <CardHeader
                className={classNames(classes.cardHeader, classes.textCenter)}
                color="success"
              >
                <h4 className={classes.cardTitle}>{t("Forgot password")}</h4>
              </CardHeader>

              <CardBody>
                <CustomInput
                  id="reset_password"
                  labelText={t("Username or Email")}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon
                          className={classes.inputAdornmentIcon}
                        />
                      </InputAdornment>
                    ),
                    value: login,
                    onChange: (event) => set_login(event.target.value),
                    autoComplete: "off",
                  }}
                />
              </CardBody>

              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  type="submit"
                  disabled={is_disabled}
                  color="success"
                  simple
                  size="lg"
                  block
                >
                  {" "}
                  {t("Reset password")}{" "}
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
