import React, { useReducer, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { MTableBodyRow } from "components/MaterialTable";
import * as queries from "constants/queries";
import * as fragments from "constants/fragments";
import * as mutations from "constants/mutations";
import MaterialTable from "../MaterialTable";

import Input from "../Input";
import Dialog from "../Dialog";
import Button from "../Button";
import { useToasts } from "react-toast-notifications";
import { useQuery, useMutation } from "react-apollo";
import { produce } from "immer";
import { isMetric } from "utils/comparsion";
import { infoColor } from "assets/jss";

const SELECT_TEMPLATE = "SELECT_TEMPLATE";
const SET_TEMPLATE_NAME = "SET_TEMPLATE_NAME";
const SET_TEMPLATE_DESCRIPTION = "SET_TEMPLATE_DESCRIPTION";
const BROWSE_TEMPLATES = "BROWSE_TEMPLATES";
const PREPARE_TEMPLATE = "PREPARE_TEMPLATE";
const SELECT_METRIC = "SELECT_METRIC";
const REMOVE_METRIC = "REMOVE_METRIC";
const DRAG_START = "DRAG_START";
const DRAG_ENTER = "DRAG_ENTER";
const START_CUSTOMIZING = "START_CUSTOMIZING";

const SELECTING_CUSTOM_METRICS = "CUSTOM_METRICS";
const SELECTING_TEMPLATE = "SELECTING_TEMPLATE";
const CREATING_TEMPLATE = "CREATING_TEMPLATE";

const reducer = (state, action) => {
  switch (action.type) {
    case SELECT_TEMPLATE:
      return produce(state, (draftState) => {
        if (action.template) {
          draftState.selectedMetrics = action.template.metrics;
          draftState.currentTemplate = action.template;
        }
      });
    case SET_TEMPLATE_NAME:
      return produce(state, (draftState) => {
        draftState.newTemplateName = action.name;
      });
    case SET_TEMPLATE_DESCRIPTION:
      return produce(state, (draftState) => {
        draftState.newTemplateDescription = action.description;
      });
    case BROWSE_TEMPLATES:
      return produce(state, (draftState) => {
        draftState.mode = SELECTING_TEMPLATE;
      });
    case START_CUSTOMIZING:
      return produce(state, (draftState) => {
        draftState.mode = SELECTING_CUSTOM_METRICS;
      });
    case PREPARE_TEMPLATE:
      return produce(state, (draftState) => {
        draftState.mode = CREATING_TEMPLATE;
      });
    case SELECT_METRIC: {
      return produce(state, (draftState) => {
        draftState.selectedMetrics.push(action.metric);
        draftState.currentTemplate = null;
      });
    }
    case REMOVE_METRIC: {
      return produce(state, (draftState) => {
        draftState.selectedMetrics = state.selectedMetrics.filter(
          (metric) => !isMetric(action.metric)(metric),
        );
        draftState.currentTemplate = null;
      });
    }
    case DRAG_START: {
      return produce(state, (draftState) => {
        draftState.metricDragged = action.metric;
      });
    }
    case DRAG_ENTER: {
      const indexDragged = state.selectedMetrics.findIndex(
        isMetric(state.metricDragged),
      );
      const indexEntered = state.selectedMetrics.findIndex(
        isMetric(action.metric),
      );
      return produce(state, (draftState) => {
        draftState.currentTemplate = null;
        draftState.selectedMetrics[indexEntered] = state.metricDragged;
        draftState.selectedMetrics[indexDragged] = action.metric;
      });
    }
    default:
      return state;
  }
};

const SelectCSVMetrics = ({ is_admin, onMetricsSelected, isOpen, close }) => {
  const { i18n, t } = useTranslation();
  const { addToast } = useToasts();
  const [
    {
      newTemplateName,
      currentTemplate,
      newTemplateDescription,
      selectedMetrics,
      mode,
    },
    dispatch,
  ] = useReducer(reducer, {
    selectedMetrics: [],
    mode: SELECTING_TEMPLATE,
    newTemplateName: "",
    newTemplateDescription: "",
    currentTemplate: null,
  });
  const onError = (error) => {
    addToast(error.toString(), { appearance: "error" });
  };

  const { data, error, loading, refetch } = useQuery(
    queries.EXPORTABLE_METRICS,
    {
      variables: {
        language: i18n.language === "sv" ? "SWEDISH" : "ENGLISH",
      },
      onError,
    },
  );

  const [create_export_template] = useMutation(
    mutations.CREATE_EXPORT_TEMPLATE,
    {
      update: (cache, { data: { create_export_template } }) => {
        cache.writeFragment({
          fragmentName: "export_template_fields",
          id: `${create_export_template.__typename}:${create_export_template.id}`,
          fragment: fragments.EXPORT_TEMPLATE_FIELDS,
          data: create_export_template,
        });
      },
      onCompleted: () => {
        refetch();
      },
      onError,
    },
  );

  const [delete_export_template] = useMutation(
    mutations.DELETE_EXPORT_TEMPLATE,
    {
      update: (cache, { data: { delete_export_template } }) => {
        cache.writeFragment({
          fragmentName: "export_template_fields",
          id: `${delete_export_template.__typename}:${delete_export_template.id}`,
          fragment: fragments.EXPORT_TEMPLATE_FIELDS,
          data: null,
        });
      },
      onCompleted: () => {
        refetch();
      },
      onError,
    },
  );

  const loadedSuccessfully = !error && !loading && Boolean(data);

  const DraggableRow = useCallback((props) => {
    const metric = props.data;
    return (
      <MTableBodyRow
        {...props}
        role="button"
        draggable="true"
        onDragStart={(e) => {
          e.dataTransfer.effectAllowed = "move";
          dispatch({ type: DRAG_START, metric });
        }}
        onDragEnter={() => {
          dispatch({ type: DRAG_ENTER, metric });
        }}
      />
    );
  }, []);

  let unselectedMetrics = [];
  if (loadedSuccessfully) {
    unselectedMetrics = data.exportable_metrics.filter(
      (metric) => !selectedMetrics?.map((m) => m.field).includes(metric.field),
    );
  }

  const maySumbit = selectedMetrics && selectedMetrics.length;
  return (
    <Dialog
      open={isOpen}
      dialogProps={{
        fullWidth: true,
        maxWidth: mode === CREATING_TEMPLATE ? "sm" : "md",
      }}
      Actions={() => {
        switch (mode) {
          case SELECTING_TEMPLATE:
            return (
              <>
                <Button type="button" color="transparent" onClick={close}>
                  {t("Close")}
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    dispatch({ type: START_CUSTOMIZING });
                  }}
                >
                  {t("Customize Columns")}
                </Button>
                <Button
                  type="button"
                  color={maySumbit ? "info" : "transparent"}
                  onClick={() => {
                    if (maySumbit) {
                      onMetricsSelected(selectedMetrics);
                    }
                  }}
                >
                  {t("Use template")}
                </Button>
              </>
            );
          case SELECTING_CUSTOM_METRICS:
            return (
              <>
                <Button
                  type="button"
                  color="transparent"
                  onClick={() => {
                    dispatch({ type: BROWSE_TEMPLATES });
                  }}
                >
                  {t("Back")}
                </Button>
                {is_admin ? (
                  <Button
                    type="button"
                    color={maySumbit ? "secondary" : "transparent"}
                    onClick={() => dispatch({ type: PREPARE_TEMPLATE })}
                  >
                    {t("Add template")}
                  </Button>
                ) : null}
                <Button
                  type="button"
                  color={maySumbit ? "info" : "transparent"}
                  onClick={() => {
                    if (maySumbit) {
                      onMetricsSelected(selectedMetrics);
                    }
                  }}
                >
                  {t("Export")}
                </Button>
              </>
            );
          case CREATING_TEMPLATE:
            return (
              <>
                <Button
                  type="button"
                  color="transparent"
                  onClick={() => dispatch({ type: START_CUSTOMIZING })}
                >
                  {t("Back")}
                </Button>
                <Button
                  type="button"
                  color={maySumbit ? "info" : "transparent"}
                  onClick={() => {
                    create_export_template({
                      variables: {
                        name: newTemplateName,
                        description: newTemplateDescription,
                        metricFields: selectedMetrics.map(({ field }) => field),
                      },
                    });
                    dispatch({ type: BROWSE_TEMPLATES });
                  }}
                >
                  {t("Create Template")}
                </Button>
              </>
            );
        }
      }}
      content={
        mode === SELECTING_TEMPLATE ? (
          <MaterialTable
            data={data?.export_templates.map((t) => ({ ...t }))}
            title={t("Choose a template")}
            columns={[
              { title: t("Name"), field: "name" },
              {
                title: t("Description"),
                field: "description",
              },
            ]}
            onRowClick={(_event, template) => {
              dispatch({ type: SELECT_TEMPLATE, template });
            }}
            editable={{
              onRowDelete: is_admin
                ? (row) => {
                    return delete_export_template({
                      variables: { id: row.id },
                    });
                  }
                : null,
            }}
            options={{
              actionsColumnIndex: -1,
              search: false,
              showTitle: true,
              rowStyle: (row) => {
                if (row.id === currentTemplate?.id) {
                  return { backgroundColor: infoColor[3] };
                }
                return {};
              },
            }}
          />
        ) : mode === SELECTING_CUSTOM_METRICS ? (
          <>
            <MaterialTable
              data={selectedMetrics.map((m) => ({ ...m }))}
              title={t("Included in the export")}
              columns={[
                { title: t("Name"), field: "label" },
                {
                  title: t("Description"),
                  field: "description",
                },
              ]}
              components={{
                Row: DraggableRow,
              }}
              onRowClick={(_event, metric) => {
                dispatch({ type: REMOVE_METRIC, metric });
              }}
              options={{
                search: true,
                showTitle: true,
              }}
            />
            <MaterialTable
              data={unselectedMetrics.map((m) => ({ ...m }))}
              title={t("Not included in the export")}
              components={{
                Row: (props) => <MTableBodyRow {...props} role="button" />,
              }}
              columns={[
                { title: t("Name"), field: "label" },
                {
                  title: t("Description"),
                  field: "description",
                },
              ]}
              onRowClick={(_event, metric) => {
                dispatch({ type: SELECT_METRIC, metric });
              }}
              options={{
                search: true,
                showTitle: true,
              }}
            />
          </>
        ) : (
          <>
            <Input
              formControlProps={{ fullWidth: true }}
              labelText={t("Template Name")}
              inputProps={{
                onChange: (event) => {
                  dispatch({
                    type: SET_TEMPLATE_NAME,
                    name: event.target.value,
                  });
                },
              }}
            />
            <Input
              formControlProps={{ fullWidth: true }}
              labelText={t("Template Description")}
              inputProps={{
                multiline: true,
                rows: 3,
                onChange: (event) => {
                  dispatch({
                    type: SET_TEMPLATE_DESCRIPTION,
                    description: event.target.value,
                  });
                },
              }}
            />
          </>
        )
      }
    />
  );
};

export default SelectCSVMetrics;
