import React, { useLayoutEffect } from "react";
import { useHistory, Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/react-hooks";

import * as queries from "../constants/queries";
import { AuthNavbar } from "../components/Navbar";
import Footer from "../components/Footer";
import routes from "../constants/routes";
import { authStyle } from "../assets/jss/layouts";

const useStyles = makeStyles(authStyle);

export default function Auth({ ...rest }) {
  const classes = useStyles();
  const { data } = useQuery(queries.IS_SIGNED_IN, {
    fetchPolicy: "cache-and-network",
  });
  const history = useHistory();

  const isLoggedIn = Boolean(data?.is_signed_in);
  useLayoutEffect(() => {
    if (isLoggedIn) {
      history.push("/admin/user");
    }
  }, [history, isLoggedIn]);

  const mainPanel = React.createRef();

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainPanel} ref={mainPanel}>
        <AuthNavbar {...rest} />

        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {routes
                .filter((route) => route.layout === "/auth")
                .map((route, i) => (
                  <Route
                    path={route.layout + route.path}
                    component={route.component}
                    key={i}
                  />
                ))}
            </Switch>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
