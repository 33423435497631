import "dotenv/config";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { ApolloProvider } from "react-apollo";
import "./assets/css/index.css";
import i18n from "./i18n";
import { ToastProvider } from "react-toast-notifications";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import svLocale from "date-fns/locale/sv";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";

import { NODE_ENV } from "constants/environment";
import Router from "./router";
import { client } from "./apollo-client";

const toast_props = {
  placement: "bottom-right",
  autoDismiss: true,
  autoDismissTimeout: 5000,
  transitionDuration: 220,
};

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "d MMM yyyy", { locale: this.locale });
  }
}
const App = () => (
  <ToastProvider {...toast_props}>
    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={svLocale}>
      <ApolloProvider client={client}>
        <I18nextProvider i18n={i18n}>
          <Router />
        </I18nextProvider>
      </ApolloProvider>
    </MuiPickersUtilsProvider>
  </ToastProvider>
);

export default App;

/* istanbul ignore if  */
if (NODE_ENV !== "test") {
  ReactDOM.render(<App />, document.getElementById("root"));
}
