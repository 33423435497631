import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/react-hooks";
import * as queries from "../../constants/queries";
import SubTable from "components/SubTable";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";

export default function UserCollarsTable({ id }) {
  const { t } = useTranslation();
  const { loading, data } = useQuery(queries.GET_USER_COLLARS, {
    variables: { id },
  });

  const table_data =
    (data && data.user && data.user.my_collars.map((obj) => ({ ...obj }))) ||
    [];

  const table_props = {
    data: table_data,
    title: t("Collars belonging to this user"),
    titleIcon: GpsFixedIcon,
    columns: [
      { title: t("Collar Name"), field: "name" },
      { title: t("Owner"), field: "user.name" },
      { title: t("Description"), field: "description" },
    ],
    options: {
      search: false,
      selection: false,
      actionsColumnIndex: -1,
      toolbar: true,
      showTitle: true,
      paging: false,
    },
    isLoading: loading,
  };

  return <SubTable {...table_props}></SubTable>;
}
